import type { SVGProps } from 'react'
import { Ref, forwardRef } from 'react'

const SvgRecording01 = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 10v4m4.5-8v12M12 3v18m4.5-15v12m4.5-8v4"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgRecording01)
export default ForwardRef
