import type { SVGProps } from 'react'
import { Ref, forwardRef } from 'react'

const SvgThermometer03 = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 3h-6m6 4h-6m6 4h-6m-9.5 2.758V4.5a2.5 2.5 0 0 1 5 0v9.258a4.5 4.5 0 1 1-5 0ZM9 17.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgThermometer03)
export default ForwardRef
