import type { SVGProps } from 'react'
import { Ref, forwardRef } from 'react'

const SvgCurrencyEthereumCircle = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.5 15.5 12 18l5.5-2.5M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-15.5-.5L12 14l5.5-2.5L12 5l-5.5 6.5Z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgCurrencyEthereumCircle)
export default ForwardRef
