import type { SVGProps } from 'react'
import { Ref, forwardRef } from 'react'

const SvgBarChart04 = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 11v10m12-10v10M9 3v18M21 3v18"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgBarChart04)
export default ForwardRef
