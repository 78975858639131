import type { SVGProps } from 'react'
import { Ref, forwardRef } from 'react'

const SvgHurricane03 = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 4H3m17 4H6m12 4H9m6 4H8m9 4h-5"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgHurricane03)
export default ForwardRef
