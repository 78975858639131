import type { SVGProps } from 'react'
import { Ref, forwardRef } from 'react'

const SvgVariable = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.906 21A20.253 20.253 0 0 0 22 12c0-3.233-.754-6.289-2.094-9M4.094 3A20.253 20.253 0 0 0 2 12c0 3.233.754 6.289 2.094 9M16.549 8.625h-.09c-.653 0-1.274.287-1.7.786l-5.374 6.303a2.233 2.233 0 0 1-1.7.786h-.09m1.12-7.875h1.394c.5 0 .939.333 1.076.816l1.774 6.243a1.12 1.12 0 0 0 1.077.816h1.394"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgVariable)
export default ForwardRef
