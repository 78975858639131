import type { SVGProps } from 'react'
import { Ref, forwardRef } from 'react'

const SvgWebcam02 = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 22h8m4.5-11.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Zm-5.313 0a3.188 3.188 0 1 1-6.375 0 3.188 3.188 0 0 1 6.376 0Z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgWebcam02)
export default ForwardRef
