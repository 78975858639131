import type { SVGProps } from 'react'
import { Ref, forwardRef } from 'react'

const SvgCloudRaining02 = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 12v8m-8-8v8m4-6v8m8-6.758a4.5 4.5 0 0 0-2.08-8.223 6.002 6.002 0 0 0-11.84 0A4.5 4.5 0 0 0 4 15.242"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgCloudRaining02)
export default ForwardRef
