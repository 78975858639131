import type { SVGProps } from 'react'
import { Ref, forwardRef } from 'react'

const SvgDisc02 = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 5.674A7 7 0 0 1 19 12M8.392 18A6.996 6.996 0 0 1 5 12m17 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-7 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgDisc02)
export default ForwardRef
