import type { SVGProps } from 'react'
import { Ref, forwardRef } from 'react'

const SvgCloudRaining01 = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20 15.242a4.5 4.5 0 0 0-2.08-8.223 6.002 6.002 0 0 0-11.84 0A4.5 4.5 0 0 0 4 15.242M8 18v2m0-8v2m8 4v2m0-8v2m-4 6v2m0-8v2"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgCloudRaining01)
export default ForwardRef
