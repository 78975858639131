import type { SVGProps } from "react"
import { Ref, forwardRef } from "react"
const SvgPolaroid = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 49 48"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="#73747F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M39.5 14.938c.717.231 1.36.447 1.934.644 2.273.783 3.565 3.024 3.108 5.383-.444 2.283-1.175 5.592-2.363 10.022-1.187 4.431-2.207 7.662-2.965 9.861-.783 2.272-3.023 3.565-5.382 3.107-2.284-.443-5.592-1.174-10.023-2.36-3.978-1.067-6.988-1.998-9.153-2.726M39.617 39.177l-5.742-1.54"
    />
    <path
      stroke="#73747F"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M5.126 29.604C5.294 32.002 7.122 33.832 9.52 34c2.32.163 5.704.313 10.292.313 4.587 0 7.971-.15 10.291-.313 2.398-.168 4.228-1.997 4.395-4.395.163-2.32.313-5.704.313-10.291 0-4.588-.15-7.972-.313-10.292-.168-2.399-1.997-4.228-4.395-4.395-2.32-.163-5.704-.314-10.291-.314-4.588 0-7.972.15-10.292.314-2.399.168-4.228 1.996-4.395 4.395-.163 2.32-.314 5.704-.314 10.292 0 4.587.15 7.971.314 10.291Z"
    />
    <path stroke="#73747F" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5.281 27.75h29.063" />
  </svg>
)
const ForwardRef = forwardRef(SvgPolaroid)
export default ForwardRef
