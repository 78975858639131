import type { SVGProps } from 'react'
import { Ref, forwardRef } from 'react'

const SvgPilcrow01 = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 4v16m0-16h2m-2 0h-5.5a4.5 4.5 0 0 0 0 9H16V4Zm-2 16h4"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgPilcrow01)
export default ForwardRef
