import type { SVGProps } from 'react'
import { Ref, forwardRef } from 'react'

const SvgTv01 = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 17v2.4c0 .56 0 .84-.109 1.054a1 1 0 0 1-.437.437C17.24 21 16.96 21 16.4 21H7.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C6 20.24 6 19.96 6 19.4V17m.8 0h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C22 14.72 22 13.88 22 12.2V7.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C19.72 3 18.88 3 17.2 3H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C2 5.28 2 6.12 2 7.8v4.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C4.28 17 5.12 17 6.8 17Z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgTv01)
export default ForwardRef
