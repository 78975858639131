import type { SVGProps } from 'react'
import { Ref, forwardRef } from 'react'

const SvgClockCheck = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m14.5 19 2 2 4.5-4.5m.985-3.95c.01-.182.015-.366.015-.55 0-5.523-4.477-10-10-10S2 6.477 2 12c0 5.435 4.337 9.858 9.739 9.997M12 6v6l3.738 1.87"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgClockCheck)
export default ForwardRef
