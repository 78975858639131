import type { SVGProps } from 'react'
import { Ref, forwardRef } from 'react'

const SvgToggle01Right = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 17H7A5 5 0 0 1 7 7h10m0 10a5 5 0 0 0 0-10m0 10a5 5 0 0 1 0-10"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgToggle01Right)
export default ForwardRef
