/* eslint-disable max-lines */
/* eslint-disable curly */
import { forwardRef, Ref } from "react"

import ActivityHeart from "./ActivityHeart"
import Activity from "./Activity"
import Airplay from "./Airplay"
import Airpods from "./Airpods"
import AlarmClockCheck from "./AlarmClockCheck"
import AlarmClockMinus from "./AlarmClockMinus"
import AlarmClockOff from "./AlarmClockOff"
import AlarmClockPlus from "./AlarmClockPlus"
import AlarmClock from "./AlarmClock"
import AlertCircle from "./AlertCircle"
import AlertHexagon from "./AlertHexagon"
import AlertOctagon from "./AlertOctagon"
import AlertSquare from "./AlertSquare"
import AlertTriangle from "./AlertTriangle"
import AlignBottom01 from "./AlignBottom01"
import AlignBottom02 from "./AlignBottom02"
import AlignCenter from "./AlignCenter"
import AlignHorizontalCentre01 from "./AlignHorizontalCentre01"
import AlignHorizontalCentre02 from "./AlignHorizontalCentre02"
import AlignJustify from "./AlignJustify"
import AlignLeft01 from "./AlignLeft01"
import AlignLeft02 from "./AlignLeft02"
import AlignLeft from "./AlignLeft"
import AlignRight01 from "./AlignRight01"
import AlignRight02 from "./AlignRight02"
import AlignRight from "./AlignRight"
import AlignTop01 from "./AlignTop01"
import AlignTop02 from "./AlignTop02"
import AlignVerticalCenter01 from "./AlignVerticalCenter01"
import AlignVerticalCenter02 from "./AlignVerticalCenter02"
import Anchor from "./Anchor"
import AnnotationAlert from "./AnnotationAlert"
import AnnotationCheck from "./AnnotationCheck"
import AnnotationDots from "./AnnotationDots"
import AnnotationHeart from "./AnnotationHeart"
import AnnotationInfo from "./AnnotationInfo"
import AnnotationPlus from "./AnnotationPlus"
import AnnotationQuestion from "./AnnotationQuestion"
import AnnotationX from "./AnnotationX"
import Annotation from "./Annotation"
import Announcement01 from "./Announcement01"
import Announcement02 from "./Announcement02"
import Announcement03 from "./Announcement03"
import Archive from "./Archive"
import ArrowBlockDown from "./ArrowBlockDown"
import ArrowBlockLeft from "./ArrowBlockLeft"
import ArrowBlockRight from "./ArrowBlockRight"
import ArrowBlockUp from "./ArrowBlockUp"
import ArrowCircleBrokenDownLeft from "./ArrowCircleBrokenDownLeft"
import ArrowCircleBrokenDownRight from "./ArrowCircleBrokenDownRight"
import ArrowCircleBrokenDown from "./ArrowCircleBrokenDown"
import ArrowCircleBrokenLeft from "./ArrowCircleBrokenLeft"
import ArrowCircleBrokenRight from "./ArrowCircleBrokenRight"
import ArrowCircleBrokenUpLeft from "./ArrowCircleBrokenUpLeft"
import ArrowCircleBrokenUpRight from "./ArrowCircleBrokenUpRight"
import ArrowCircleBrokenUp from "./ArrowCircleBrokenUp"
import ArrowCircleDownLeft from "./ArrowCircleDownLeft"
import ArrowCircleDownRight from "./ArrowCircleDownRight"
import ArrowCircleDown from "./ArrowCircleDown"
import ArrowCircleLeft from "./ArrowCircleLeft"
import ArrowCircleRight from "./ArrowCircleRight"
import ArrowCircleUpLeft from "./ArrowCircleUpLeft"
import ArrowCircleUpRight from "./ArrowCircleUpRight"
import ArrowCircleUp from "./ArrowCircleUp"
import ArrowDownLeft from "./ArrowDownLeft"
import ArrowDownRight from "./ArrowDownRight"
import ArrowDown from "./ArrowDown"
import ArrowLeft from "./ArrowLeft"
import ArrowNarrowDownLeft from "./ArrowNarrowDownLeft"
import ArrowNarrowDownRight from "./ArrowNarrowDownRight"
import ArrowNarrowDown from "./ArrowNarrowDown"
import ArrowNarrowLeft from "./ArrowNarrowLeft"
import ArrowNarrowRight from "./ArrowNarrowRight"
import ArrowNarrowUpLeft from "./ArrowNarrowUpLeft"
import ArrowNarrowUpRight from "./ArrowNarrowUpRight"
import ArrowNarrowUp from "./ArrowNarrowUp"
import ArrowRight from "./ArrowRight"
import ArrowSquareDownLeft from "./ArrowSquareDownLeft"
import ArrowSquareDownRight from "./ArrowSquareDownRight"
import ArrowSquareDown from "./ArrowSquareDown"
import ArrowSquareLeft from "./ArrowSquareLeft"
import ArrowSquareRight from "./ArrowSquareRight"
import ArrowSquareUpLeft from "./ArrowSquareUpLeft"
import ArrowSquareUpRight from "./ArrowSquareUpRight"
import ArrowSquareUp from "./ArrowSquareUp"
import ArrowUpLeft from "./ArrowUpLeft"
import ArrowUpRight from "./ArrowUpRight"
import ArrowUp from "./ArrowUp"
import ArrowsDown from "./ArrowsDown"
import ArrowsLeft from "./ArrowsLeft"
import ArrowsRight from "./ArrowsRight"
import ArrowsTriangle from "./ArrowsTriangle"
import ArrowsUp from "./ArrowsUp"
import Asterisk01 from "./Asterisk01"
import Asterisk02 from "./Asterisk02"
import AtSign from "./AtSign"
import Atom01 from "./Atom01"
import Atom02 from "./Atom02"
import Attachment01 from "./Attachment01"
import Attachment02 from "./Attachment02"
import Award01 from "./Award01"
import Award02 from "./Award02"
import Award03 from "./Award03"
import Award04 from "./Award04"
import Award05 from "./Award05"
import Backpack from "./Backpack"
import BankNote01 from "./BankNote01"
import BankNote02 from "./BankNote02"
import BankNote03 from "./BankNote03"
import Bank from "./Bank"
import BarChart01 from "./BarChart01"
import BarChart02 from "./BarChart02"
import BarChart03 from "./BarChart03"
import BarChart04 from "./BarChart04"
import BarChart05 from "./BarChart05"
import BarChart06 from "./BarChart06"
import BarChart07 from "./BarChart07"
import BarChart08 from "./BarChart08"
import BarChart09 from "./BarChart09"
import BarChart10 from "./BarChart10"
import BarChart11 from "./BarChart11"
import BarChart12 from "./BarChart12"
import BarChartCircle01 from "./BarChartCircle01"
import BarChartCircle02 from "./BarChartCircle02"
import BarChartCircle03 from "./BarChartCircle03"
import BarChartSquare01 from "./BarChartSquare01"
import BarChartSquare02 from "./BarChartSquare02"
import BarChartSquare03 from "./BarChartSquare03"
import BarChartSquareDown from "./BarChartSquareDown"
import BarChartSquareMinus from "./BarChartSquareMinus"
import BarChartSquarePlus from "./BarChartSquarePlus"
import BarChartSquareUp from "./BarChartSquareUp"
import BarLineChart from "./BarLineChart"
import BatteryCharging01 from "./BatteryCharging01"
import BatteryCharging02 from "./BatteryCharging02"
import BatteryEmpty from "./BatteryEmpty"
import BatteryFull from "./BatteryFull"
import BatteryLow from "./BatteryLow"
import BatteryMid from "./BatteryMid"
import Beaker01 from "./Beaker01"
import Beaker02 from "./Beaker02"
import Bell01 from "./Bell01"
import Bell02 from "./Bell02"
import Bell03 from "./Bell03"
import Bell04 from "./Bell04"
import BellMinus from "./BellMinus"
import BellOff01 from "./BellOff01"
import BellOff02 from "./BellOff02"
import BellOff03 from "./BellOff03"
import BellPlus from "./BellPlus"
import BellRinging01 from "./BellRinging01"
import BellRinging02 from "./BellRinging02"
import BellRinging03 from "./BellRinging03"
import BellRinging04 from "./BellRinging04"
import BezierCurve01 from "./BezierCurve01"
import BezierCurve02 from "./BezierCurve02"
import BezierCurve03 from "./BezierCurve03"
import BluetoothConnect from "./BluetoothConnect"
import BluetoothOff from "./BluetoothOff"
import BluetoothOn from "./BluetoothOn"
import BluetoothSignal from "./BluetoothSignal"
import Bold01 from "./Bold01"
import Bold02 from "./Bold02"
import BoldSquare from "./BoldSquare"
import BookClosed from "./BookClosed"
import BookOpen01 from "./BookOpen01"
import BookOpen02 from "./BookOpen02"
import BookmarkAdd from "./BookmarkAdd"
import BookmarkCheck from "./BookmarkCheck"
import BookmarkMinus from "./BookmarkMinus"
import BookmarkX from "./BookmarkX"
import Bookmark from "./Bookmark"
import Box from "./Box"
import BracketsCheck from "./BracketsCheck"
import BracketsEllipses from "./BracketsEllipses"
import BracketsMinus from "./BracketsMinus"
import BracketsPlus from "./BracketsPlus"
import BracketsSlash from "./BracketsSlash"
import BracketsX from "./BracketsX"
import Brackets from "./Brackets"
import Briefcase01 from "./Briefcase01"
import Briefcase02 from "./Briefcase02"
import Browser from "./Browser"
import Brush01 from "./Brush01"
import Brush02 from "./Brush02"
import Brush03 from "./Brush03"
import Building01 from "./Building01"
import Building02 from "./Building02"
import Building03 from "./Building03"
import Building04 from "./Building04"
import Building05 from "./Building05"
import Building06 from "./Building06"
import Building07 from "./Building07"
import Building08 from "./Building08"
import Bus from "./Bus"
import Calculator from "./Calculator"
import CalendarCheck01 from "./CalendarCheck01"
import CalendarCheck02 from "./CalendarCheck02"
import CalendarDate from "./CalendarDate"
import CalendarHeart01 from "./CalendarHeart01"
import CalendarHeart02 from "./CalendarHeart02"
import CalendarMinus01 from "./CalendarMinus01"
import CalendarMinus02 from "./CalendarMinus02"
import CalendarPlus01 from "./CalendarPlus01"
import CalendarPlus02 from "./CalendarPlus02"
import Calendar from "./Calendar"
import Camera01 from "./Camera01"
import Camera02 from "./Camera02"
import Camera03 from "./Camera03"
import CameraLens from "./CameraLens"
import CameraOff from "./CameraOff"
import CameraPlus from "./CameraPlus"
import Car01 from "./Car01"
import Car02 from "./Car02"
import Certificate01 from "./Certificate01"
import Certificate02 from "./Certificate02"
import ChartBreakoutCircle from "./ChartBreakoutCircle"
import ChartBreakoutSquare from "./ChartBreakoutSquare"
import CheckCircleBroken from "./CheckCircleBroken"
import CheckCircle from "./CheckCircle"
import CheckDone01 from "./CheckDone01"
import CheckDone02 from "./CheckDone02"
import CheckHeart from "./CheckHeart"
import CheckSquareBroken from "./CheckSquareBroken"
import CheckSquare from "./CheckSquare"
import CheckVerified01 from "./CheckVerified01"
import CheckVerified02 from "./CheckVerified02"
import CheckVerified03 from "./CheckVerified03"
import Check from "./Check"
import ChevronDownDouble from "./ChevronDownDouble"
import ChevronDown from "./ChevronDown"
import ChevronLeftDouble from "./ChevronLeftDouble"
import ChevronLeft from "./ChevronLeft"
import ChevronRightDouble from "./ChevronRightDouble"
import ChevronRight from "./ChevronRight"
import ChevronSelectorHorizontal from "./ChevronSelectorHorizontal"
import ChevronSelectorVertical from "./ChevronSelectorVertical"
import ChevronUpDouble from "./ChevronUpDouble"
import ChevronUp from "./ChevronUp"
import ChromeCast from "./ChromeCast"
import CircleCut from "./CircleCut"
import Circle from "./Circle"
import Clapperboard from "./Clapperboard"
import ClipboardAttachment from "./ClipboardAttachment"
import ClipboardCheck from "./ClipboardCheck"
import ClipboardDownload from "./ClipboardDownload"
import ClipboardMinus from "./ClipboardMinus"
import ClipboardPlus from "./ClipboardPlus"
import ClipboardX from "./ClipboardX"
import Clipboard from "./Clipboard"
import ClockCheck from "./ClockCheck"
import ClockFastForward from "./ClockFastForward"
import ClockPlus from "./ClockPlus"
import ClockRefresh from "./ClockRefresh"
import ClockRewind from "./ClockRewind"
import ClockSnooze from "./ClockSnooze"
import ClockStopwatch from "./ClockStopwatch"
import Clock from "./Clock"
import Cloud01 from "./Cloud01"
import Cloud02 from "./Cloud02"
import Cloud03 from "./Cloud03"
import CloudBlank01 from "./CloudBlank01"
import CloudBlank02 from "./CloudBlank02"
import CloudLightning from "./CloudLightning"
import CloudMoon from "./CloudMoon"
import CloudOff from "./CloudOff"
import CloudRaining01 from "./CloudRaining01"
import CloudRaining02 from "./CloudRaining02"
import CloudRaining03 from "./CloudRaining03"
import CloudRaining04 from "./CloudRaining04"
import CloudRaining05 from "./CloudRaining05"
import CloudRaining06 from "./CloudRaining06"
import CloudSnowing01 from "./CloudSnowing01"
import CloudSnowing02 from "./CloudSnowing02"
import CloudSun01 from "./CloudSun01"
import CloudSun02 from "./CloudSun02"
import CloudSun03 from "./CloudSun03"
import Code01 from "./Code01"
import Code02 from "./Code02"
import CodeBrowser from "./CodeBrowser"
import CodeCircle01 from "./CodeCircle01"
import CodeCircle02 from "./CodeCircle02"
import CodeCircle03 from "./CodeCircle03"
import CodeSnippet01 from "./CodeSnippet01"
import CodeSnippet02 from "./CodeSnippet02"
import CodeSquare01 from "./CodeSquare01"
import CodeSquare02 from "./CodeSquare02"
import Codepen from "./Codepen"
import Coins01 from "./Coins01"
import Coins02 from "./Coins02"
import Coins03 from "./Coins03"
import Coins04 from "./Coins04"
import CoinsHand from "./CoinsHand"
import CoinsStacked01 from "./CoinsStacked01"
import CoinsStacked02 from "./CoinsStacked02"
import CoinsStacked03 from "./CoinsStacked03"
import CoinsStacked04 from "./CoinsStacked04"
import CoinsSwap01 from "./CoinsSwap01"
import CoinsSwap02 from "./CoinsSwap02"
import Colors2 from "./Colors2"
import Colors from "./Colors"
import Columns01 from "./Columns01"
import Columns02 from "./Columns02"
import Columns03 from "./Columns03"
import Command from "./Command"
import Compass01 from "./Compass01"
import Compass02 from "./Compass02"
import Compass03 from "./Compass03"
import Compass from "./Compass"
import Container from "./Container"
import Contrast01 from "./Contrast01"
import Contrast02 from "./Contrast02"
import Contrast03 from "./Contrast03"
import Copy01 from "./Copy01"
import Copy02 from "./Copy02"
import Copy03 from "./Copy03"
import Copy04 from "./Copy04"
import Copy05 from "./Copy05"
import Copy06 from "./Copy06"
import Copy07 from "./Copy07"
import CornerDownLeft from "./CornerDownLeft"
import CornerDownRight from "./CornerDownRight"
import CornerLeftDown from "./CornerLeftDown"
import CornerLeftUp from "./CornerLeftUp"
import CornerRightDown from "./CornerRightDown"
import CornerRightUp from "./CornerRightUp"
import CornerUpLeft from "./CornerUpLeft"
import CornerUpRight from "./CornerUpRight"
import CpuChip01 from "./CpuChip01"
import CpuChip02 from "./CpuChip02"
import CreditCard01 from "./CreditCard01"
import CreditCard02 from "./CreditCard02"
import CreditCardCheck from "./CreditCardCheck"
import CreditCardDown from "./CreditCardDown"
import CreditCardDownload from "./CreditCardDownload"
import CreditCardEdit from "./CreditCardEdit"
import CreditCardLock from "./CreditCardLock"
import CreditCardMinus from "./CreditCardMinus"
import CreditCardPlus from "./CreditCardPlus"
import CreditCardRefresh from "./CreditCardRefresh"
import CreditCardSearch from "./CreditCardSearch"
import CreditCardShield from "./CreditCardShield"
import CreditCardUp from "./CreditCardUp"
import CreditCardUpload from "./CreditCardUpload"
import CreditCardX from "./CreditCardX"
import Crop01 from "./Crop01"
import Crop02 from "./Crop02"
import Cryptocurrency01 from "./Cryptocurrency01"
import Cryptocurrency02 from "./Cryptocurrency02"
import Cryptocurrency03 from "./Cryptocurrency03"
import Cryptocurrency04 from "./Cryptocurrency04"
import Cube01 from "./Cube01"
import Cube02 from "./Cube02"
import Cube03 from "./Cube03"
import Cube04 from "./Cube04"
import CubeOutline from "./CubeOutline"
import CurrencyBitcoinCircle from "./CurrencyBitcoinCircle"
import CurrencyBitcoin from "./CurrencyBitcoin"
import CurrencyDollarCircle from "./CurrencyDollarCircle"
import CurrencyDollar from "./CurrencyDollar"
import CurrencyEthereumCircle from "./CurrencyEthereumCircle"
import CurrencyEthereum from "./CurrencyEthereum"
import CurrencyEuroCircle from "./CurrencyEuroCircle"
import CurrencyEuro from "./CurrencyEuro"
import CurrencyPoundCircle from "./CurrencyPoundCircle"
import CurrencyPound from "./CurrencyPound"
import CurrencyRubleCircle from "./CurrencyRubleCircle"
import CurrencyRuble from "./CurrencyRuble"
import CurrencyRupeeCircle from "./CurrencyRupeeCircle"
import CurrencyRupee from "./CurrencyRupee"
import CurrencyYenCircle from "./CurrencyYenCircle"
import CurrencyYen from "./CurrencyYen"
import Cursor01 from "./Cursor01"
import Cursor02 from "./Cursor02"
import Cursor03 from "./Cursor03"
import Cursor04 from "./Cursor04"
import CursorBox from "./CursorBox"
import CursorClick01 from "./CursorClick01"
import CursorClick02 from "./CursorClick02"
import Data from "./Data"
import Database01 from "./Database01"
import Database02 from "./Database02"
import Database03 from "./Database03"
import Dataflow01 from "./Dataflow01"
import Dataflow02 from "./Dataflow02"
import Dataflow03 from "./Dataflow03"
import Dataflow04 from "./Dataflow04"
import Delete from "./Delete"
import Diamond01 from "./Diamond01"
import Diamond02 from "./Diamond02"
import Dice1 from "./Dice1"
import Dice2 from "./Dice2"
import Dice3 from "./Dice3"
import Dice4 from "./Dice4"
import Dice5 from "./Dice5"
import Dice6 from "./Dice6"
import Disc01 from "./Disc01"
import Disc02 from "./Disc02"
import DistributeSpacingHorizontal from "./DistributeSpacingHorizontal"
import DistributeSpacingVertical from "./DistributeSpacingVertical"
import Divide01 from "./Divide01"
import Divide02 from "./Divide02"
import Divide03 from "./Divide03"
import Divider from "./Divider"
import Dotpoints01 from "./Dotpoints01"
import Dotpoints02 from "./Dotpoints02"
import DotsGrid from "./DotsGrid"
import DotsHorizontal from "./DotsHorizontal"
import DotsVertical from "./DotsVertical"
import Download01 from "./Download01"
import Download02 from "./Download02"
import Download03 from "./Download03"
import Download04 from "./Download04"
import DownloadCloud01 from "./DownloadCloud01"
import DownloadCloud02 from "./DownloadCloud02"
import Drop from "./Drop"
import Droplets01 from "./Droplets01"
import Droplets02 from "./Droplets02"
import Droplets03 from "./Droplets03"
import Dropper from "./Dropper"
import Edit01 from "./Edit01"
import Edit02 from "./Edit02"
import Edit03 from "./Edit03"
import Edit04 from "./Edit04"
import Edit05 from "./Edit05"
import EqualNot from "./EqualNot"
import Equal from "./Equal"
import Eraser from "./Eraser"
import Expand01 from "./Expand01"
import Expand02 from "./Expand02"
import Expand03 from "./Expand03"
import Expand04 from "./Expand04"
import Expand05 from "./Expand05"
import Expand06 from "./Expand06"
import EyeOff from "./EyeOff"
import Eye from "./Eye"
import FaceContent from "./FaceContent"
import FaceFrown from "./FaceFrown"
import FaceHappy from "./FaceHappy"
import FaceIdSquare from "./FaceIdSquare"
import FaceId from "./FaceId"
import FaceNeutral from "./FaceNeutral"
import FaceSad from "./FaceSad"
import FaceSmile from "./FaceSmile"
import FaceWink from "./FaceWink"
import FastBackward from "./FastBackward"
import FastForward from "./FastForward"
import Feather from "./Feather"
import Figma from "./Figma"
import File01 from "./File01"
import File02 from "./File02"
import File03 from "./File03"
import File04 from "./File04"
import File05 from "./File05"
import File06 from "./File06"
import File07 from "./File07"
import FileAttachment01 from "./FileAttachment01"
import FileAttachment02 from "./FileAttachment02"
import FileAttachment03 from "./FileAttachment03"
import FileAttachment04 from "./FileAttachment04"
import FileAttachment05 from "./FileAttachment05"
import FileCheck01 from "./FileCheck01"
import FileCheck02 from "./FileCheck02"
import FileCheck03 from "./FileCheck03"
import FileCode01 from "./FileCode01"
import FileCode02 from "./FileCode02"
import FileDownload01 from "./FileDownload01"
import FileDownload02 from "./FileDownload02"
import FileDownload03 from "./FileDownload03"
import FileHeart01 from "./FileHeart01"
import FileHeart02 from "./FileHeart02"
import FileHeart03 from "./FileHeart03"
import FileLock01 from "./FileLock01"
import FileLock02 from "./FileLock02"
import FileLock03 from "./FileLock03"
import FileMinus01 from "./FileMinus01"
import FileMinus02 from "./FileMinus02"
import FileMinus03 from "./FileMinus03"
import FilePlus01 from "./FilePlus01"
import FilePlus02 from "./FilePlus02"
import FilePlus03 from "./FilePlus03"
import FileQuestion01 from "./FileQuestion01"
import FileQuestion02 from "./FileQuestion02"
import FileQuestion03 from "./FileQuestion03"
import FileSearch01 from "./FileSearch01"
import FileSearch02 from "./FileSearch02"
import FileSearch03 from "./FileSearch03"
import FileShield01 from "./FileShield01"
import FileShield02 from "./FileShield02"
import FileShield03 from "./FileShield03"
import FileX01 from "./FileX01"
import FileX02 from "./FileX02"
import FileX03 from "./FileX03"
import Film01 from "./Film01"
import Film02 from "./Film02"
import Film03 from "./Film03"
import FilterFunnel01 from "./FilterFunnel01"
import FilterFunnel02 from "./FilterFunnel02"
import FilterLines from "./FilterLines"
import Fingerprint01 from "./Fingerprint01"
import Fingerprint02 from "./Fingerprint02"
import Fingerprint03 from "./Fingerprint03"
import Fingerprint04 from "./Fingerprint04"
import Flag01 from "./Flag01"
import Flag02 from "./Flag02"
import Flag03 from "./Flag03"
import Flag04 from "./Flag04"
import Flag05 from "./Flag05"
import Flag06 from "./Flag06"
import FlashOff from "./FlashOff"
import Flash from "./Flash"
import FlexAlignBottom from "./FlexAlignBottom"
import FlexAlignLeft from "./FlexAlignLeft"
import FlexAlignRight from "./FlexAlignRight"
import FlexAlignTop from "./FlexAlignTop"
import FlipBackward from "./FlipBackward"
import FlipForward from "./FlipForward"
import FolderCheck from "./FolderCheck"
import FolderClosed from "./FolderClosed"
import FolderCode from "./FolderCode"
import FolderDownload from "./FolderDownload"
import FolderLock from "./FolderLock"
import FolderMinus from "./FolderMinus"
import FolderPlus from "./FolderPlus"
import FolderQuestion from "./FolderQuestion"
import FolderSearch from "./FolderSearch"
import FolderShield from "./FolderShield"
import FolderX from "./FolderX"
import Folder from "./Folder"
import Framer from "./Framer"
import GamingPad01 from "./GamingPad01"
import GamingPad02 from "./GamingPad02"
import Gift01 from "./Gift01"
import Gift02 from "./Gift02"
import GitBranch01 from "./GitBranch01"
import GitBranch02 from "./GitBranch02"
import GitCommit from "./GitCommit"
import GitMerge from "./GitMerge"
import GitPullRequest from "./GitPullRequest"
import Glasses01 from "./Glasses01"
import Glasses02 from "./Glasses02"
import Globe01 from "./Globe01"
import Globe02 from "./Globe02"
import Globe03 from "./Globe03"
import Globe04 from "./Globe04"
import Globe05 from "./Globe05"
import Globe06 from "./Globe06"
import GlobeSlated01 from "./GlobeSlated01"
import GlobeSlated02 from "./GlobeSlated02"
import GoogleChrome from "./GoogleChrome"
import GraduationHat01 from "./GraduationHat01"
import GraduationHat02 from "./GraduationHat02"
import Grid01 from "./Grid01"
import Grid02 from "./Grid02"
import Grid03 from "./Grid03"
import GridDotsBlank from "./GridDotsBlank"
import GridDotsBottom from "./GridDotsBottom"
import GridDotsHorizontalCenter from "./GridDotsHorizontalCenter"
import GridDotsLeft from "./GridDotsLeft"
import GridDotsOuter from "./GridDotsOuter"
import GridDotsRight from "./GridDotsRight"
import GridDotsTop from "./GridDotsTop"
import GridDotsVerticalCenter from "./GridDotsVerticalCenter"
import Hand from "./Hand"
import HardDrive from "./HardDrive"
import Hash01 from "./Hash01"
import Hash02 from "./Hash02"
import Heading01 from "./Heading01"
import Heading02 from "./Heading02"
import HeadingSquare from "./HeadingSquare"
import Headphones01 from "./Headphones01"
import Headphones02 from "./Headphones02"
import HeartCircle from "./HeartCircle"
import HeartHand from "./HeartHand"
import HeartHexagon from "./HeartHexagon"
import HeartOctagon from "./HeartOctagon"
import HeartRounded from "./HeartRounded"
import HeartSquare from "./HeartSquare"
import Heart from "./Heart"
import Hearts from "./Hearts"
import HelpCircle from "./HelpCircle"
import HelpHexagon from "./HelpHexagon"
import HelpOctagon from "./HelpOctagon"
import HelpSquare from "./HelpSquare"
import Hexagon01 from "./Hexagon01"
import Hexagon02 from "./Hexagon02"
import Home01 from "./Home01"
import Home02 from "./Home02"
import Home03 from "./Home03"
import Home04 from "./Home04"
import Home05 from "./Home05"
import HomeLine from "./HomeLine"
import HomeSmile from "./HomeSmile"
import HorizontalBarChart01 from "./HorizontalBarChart01"
import HorizontalBarChart02 from "./HorizontalBarChart02"
import HorizontalBarChart03 from "./HorizontalBarChart03"
import Hourglass01 from "./Hourglass01"
import Hourglass02 from "./Hourglass02"
import Hourglass03 from "./Hourglass03"
import Hurricane01 from "./Hurricane01"
import Hurricane02 from "./Hurricane02"
import Hurricane03 from "./Hurricane03"
import Image01 from "./Image01"
import Image02 from "./Image02"
import Image03 from "./Image03"
import Image04 from "./Image04"
import Image05 from "./Image05"
import ImageCheck from "./ImageCheck"
import ImageDown from "./ImageDown"
import ImageIndentLeft from "./ImageIndentLeft"
import ImageIndentRight from "./ImageIndentRight"
import ImageLeft from "./ImageLeft"
import ImagePlus from "./ImagePlus"
import ImageRight from "./ImageRight"
import ImageUp from "./ImageUp"
import ImageUserCheck from "./ImageUserCheck"
import ImageUserDown from "./ImageUserDown"
import ImageUserLeft from "./ImageUserLeft"
import ImageUserPlus from "./ImageUserPlus"
import ImageUserRight from "./ImageUserRight"
import ImageUserUp from "./ImageUserUp"
import ImageUserX from "./ImageUserX"
import ImageUser from "./ImageUser"
import ImageX from "./ImageX"
import Inbox01 from "./Inbox01"
import Inbox02 from "./Inbox02"
import Infinity from "./Infinity"
import InfoCircle from "./InfoCircle"
import InfoHexagon from "./InfoHexagon"
import InfoOctagon from "./InfoOctagon"
import InfoSquare from "./InfoSquare"
import IntersectCircle from "./IntersectCircle"
import IntersectSquare from "./IntersectSquare"
import Italic01 from "./Italic01"
import Italic02 from "./Italic02"
import ItalicSquare from "./ItalicSquare"
import Key01 from "./Key01"
import Key02 from "./Key02"
import Keyboard01 from "./Keyboard01"
import Keyboard02 from "./Keyboard02"
import Laptop01 from "./Laptop01"
import Laptop02 from "./Laptop02"
import LayerSingle from "./LayerSingle"
import LayersThree01 from "./LayersThree01"
import LayersThree02 from "./LayersThree02"
import LayersTwo01 from "./LayersTwo01"
import LayersTwo02 from "./LayersTwo02"
import LayoutAlt01 from "./LayoutAlt01"
import LayoutAlt02 from "./LayoutAlt02"
import LayoutAlt03 from "./LayoutAlt03"
import LayoutAlt04 from "./LayoutAlt04"
import LayoutBottom from "./LayoutBottom"
import LayoutGrid01 from "./LayoutGrid01"
import LayoutGrid02 from "./LayoutGrid02"
import LayoutLeft from "./LayoutLeft"
import LayoutRight from "./LayoutRight"
import LayoutTop from "./LayoutTop"
import LeftIndent01 from "./LeftIndent01"
import LeftIndent02 from "./LeftIndent02"
import LetterSpacing01 from "./LetterSpacing01"
import LetterSpacing02 from "./LetterSpacing02"
import LifeBuoy01 from "./LifeBuoy01"
import LifeBuoy02 from "./LifeBuoy02"
import Lightbulb01 from "./Lightbulb01"
import Lightbulb02 from "./Lightbulb02"
import Lightbulb03 from "./Lightbulb03"
import Lightbulb04 from "./Lightbulb04"
import Lightbulb05 from "./Lightbulb05"
import Lightning01 from "./Lightning01"
import Lightning02 from "./Lightning02"
import LineChartDown01 from "./LineChartDown01"
import LineChartDown02 from "./LineChartDown02"
import LineChartDown03 from "./LineChartDown03"
import LineChartDown04 from "./LineChartDown04"
import LineChartDown05 from "./LineChartDown05"
import LineChartUp01 from "./LineChartUp01"
import LineChartUp02 from "./LineChartUp02"
import LineChartUp03 from "./LineChartUp03"
import LineChartUp04 from "./LineChartUp04"
import LineChartUp05 from "./LineChartUp05"
import LineHeight from "./LineHeight"
import Link01 from "./Link01"
import Link02 from "./Link02"
import Link03 from "./Link03"
import Link04 from "./Link04"
import Link05 from "./Link05"
import LinkBroken01 from "./LinkBroken01"
import LinkBroken02 from "./LinkBroken02"
import LinkExternal01 from "./LinkExternal01"
import LinkExternal02 from "./LinkExternal02"
import List from "./List"
import Loading01 from "./Loading01"
import Loading02 from "./Loading02"
import Loading03 from "./Loading03"
import Lock01 from "./Lock01"
import Lock02 from "./Lock02"
import Lock03 from "./Lock03"
import Lock04 from "./Lock04"
import LockKeyholeCircle from "./LockKeyholeCircle"
import LockKeyholeSquare from "./LockKeyholeSquare"
import LockUnlocked01 from "./LockUnlocked01"
import LockUnlocked02 from "./LockUnlocked02"
import LockUnlocked03 from "./LockUnlocked03"
import LockUnlocked04 from "./LockUnlocked04"
import LogIn01 from "./LogIn01"
import LogIn02 from "./LogIn02"
import LogIn03 from "./LogIn03"
import LogIn04 from "./LogIn04"
import LogOut01 from "./LogOut01"
import LogOut02 from "./LogOut02"
import LogOut03 from "./LogOut03"
import LogOut04 from "./LogOut04"
import Luggage01 from "./Luggage01"
import Luggage02 from "./Luggage02"
import Luggage03 from "./Luggage03"
import MagicWand01 from "./MagicWand01"
import MagicWand02 from "./MagicWand02"
import Mail01 from "./Mail01"
import Mail02 from "./Mail02"
import Mail03 from "./Mail03"
import Mail04 from "./Mail04"
import Mail05 from "./Mail05"
import Map01 from "./Map01"
import Map02 from "./Map02"
import Mark from "./Mark"
import MarkerPin01 from "./MarkerPin01"
import MarkerPin02 from "./MarkerPin02"
import MarkerPin03 from "./MarkerPin03"
import MarkerPin04 from "./MarkerPin04"
import MarkerPin05 from "./MarkerPin05"
import MarkerPin06 from "./MarkerPin06"
import Maximize01 from "./Maximize01"
import Maximize02 from "./Maximize02"
import MedicalCircle from "./MedicalCircle"
import MedicalCross from "./MedicalCross"
import MedicalSquare from "./MedicalSquare"
import Menu01 from "./Menu01"
import Menu02 from "./Menu02"
import Menu03 from "./Menu03"
import Menu04 from "./Menu04"
import Menu05 from "./Menu05"
import MessageAlertCircle from "./MessageAlertCircle"
import MessageAlertSquare from "./MessageAlertSquare"
import MessageChatCircle from "./MessageChatCircle"
import MessageChatSquare from "./MessageChatSquare"
import MessageCheckCircle from "./MessageCheckCircle"
import MessageCheckSquare from "./MessageCheckSquare"
import MessageCircle01 from "./MessageCircle01"
import MessageCircle02 from "./MessageCircle02"
import MessageDotsCircle from "./MessageDotsCircle"
import MessageDotsSquare from "./MessageDotsSquare"
import MessageHeartCircle from "./MessageHeartCircle"
import MessageHeartSquare from "./MessageHeartSquare"
import MessageNotificationCircle from "./MessageNotificationCircle"
import MessageNotificationSquare from "./MessageNotificationSquare"
import MessagePlusCircle from "./MessagePlusCircle"
import MessagePlusSquare from "./MessagePlusSquare"
import MessageQuestionCircle from "./MessageQuestionCircle"
import MessageQuestionSquare from "./MessageQuestionSquare"
import MessageSmileCircle from "./MessageSmileCircle"
import MessageSmileSquare from "./MessageSmileSquare"
import MessageSquare01 from "./MessageSquare01"
import MessageSquare02 from "./MessageSquare02"
import MessageTextCircle01 from "./MessageTextCircle01"
import MessageTextCircle02 from "./MessageTextCircle02"
import MessageTextSquare01 from "./MessageTextSquare01"
import MessageTextSquare02 from "./MessageTextSquare02"
import MessageXCircle from "./MessageXCircle"
import MessageXSquare from "./MessageXSquare"
import Microphone01 from "./Microphone01"
import Microphone02 from "./Microphone02"
import MicrophoneOff01 from "./MicrophoneOff01"
import MicrophoneOff02 from "./MicrophoneOff02"
import Microscope from "./Microscope"
import Minimize01 from "./Minimize01"
import Minimize02 from "./Minimize02"
import MinusCircle from "./MinusCircle"
import MinusSquare from "./MinusSquare"
import Minus from "./Minus"
import Modem01 from "./Modem01"
import Modem02 from "./Modem02"
import Monitor01 from "./Monitor01"
import Monitor02 from "./Monitor02"
import Monitor03 from "./Monitor03"
import Monitor04 from "./Monitor04"
import Monitor05 from "./Monitor05"
import Moon01 from "./Moon01"
import Moon02 from "./Moon02"
import MoonEclipse from "./MoonEclipse"
import MoonStar from "./MoonStar"
import Mouse from "./Mouse"
import Move from "./Move"
import MusicNote01 from "./MusicNote01"
import MusicNote02 from "./MusicNote02"
import MusicNotePlus from "./MusicNotePlus"
import NavigationPointer01 from "./NavigationPointer01"
import NavigationPointer02 from "./NavigationPointer02"
import NavigationPointerOff01 from "./NavigationPointerOff01"
import NavigationPointerOff02 from "./NavigationPointerOff02"
import NotificationBox from "./NotificationBox"
import NotificationMessage from "./NotificationMessage"
import NotificationText from "./NotificationText"
import Octagon from "./Octagon"
import PackageCheck from "./PackageCheck"
import PackageMinus from "./PackageMinus"
import PackagePlus from "./PackagePlus"
import PackageSearch from "./PackageSearch"
import PackageX from "./PackageX"
import Package from "./Package"
import PaintPour from "./PaintPour"
import Paint from "./Paint"
import Palette from "./Palette"
import Paperclip from "./Paperclip"
import ParagraphSpacing from "./ParagraphSpacing"
import ParagraphWrap from "./ParagraphWrap"
import PasscodeLock from "./PasscodeLock"
import Passcode from "./Passcode"
import Passport from "./Passport"
import PauseCircle from "./PauseCircle"
import PauseSquare from "./PauseSquare"
import PenTool01 from "./PenTool01"
import PenTool02 from "./PenTool02"
import PenToolMinus from "./PenToolMinus"
import PenToolPlus from "./PenToolPlus"
import Pencil01 from "./Pencil01"
import Pencil02 from "./Pencil02"
import PencilLine from "./PencilLine"
import Pentagon from "./Pentagon"
import Percent01 from "./Percent01"
import Percent02 from "./Percent02"
import Percent03 from "./Percent03"
import Perspective01 from "./Perspective01"
import Perspective02 from "./Perspective02"
import Phone01 from "./Phone01"
import Phone02 from "./Phone02"
import PhoneCall01 from "./PhoneCall01"
import PhoneCall02 from "./PhoneCall02"
import PhoneHangUp from "./PhoneHangUp"
import PhoneIncoming01 from "./PhoneIncoming01"
import PhoneIncoming02 from "./PhoneIncoming02"
import PhoneOutgoing01 from "./PhoneOutgoing01"
import PhoneOutgoing02 from "./PhoneOutgoing02"
import PhonePause from "./PhonePause"
import PhonePlus from "./PhonePlus"
import PhoneX from "./PhoneX"
import Phone from "./Phone"
import PieChart01 from "./PieChart01"
import PieChart02 from "./PieChart02"
import PieChart03 from "./PieChart03"
import PieChart04 from "./PieChart04"
import PiggyBank01 from "./PiggyBank01"
import PiggyBank02 from "./PiggyBank02"
import Pilcrow01 from "./Pilcrow01"
import Pilcrow02 from "./Pilcrow02"
import PilcrowSquare from "./PilcrowSquare"
import Pin01 from "./Pin01"
import Pin02 from "./Pin02"
import Placeholder from "./Placeholder"
import Plane from "./Plane"
import PlayCircle from "./PlayCircle"
import PlaySquare from "./PlaySquare"
import Play from "./Play"
import PlusCircle from "./PlusCircle"
import PlusSquare from "./PlusSquare"
import Plus from "./Plus"
import Podcast from "./Podcast"
import Polaroid from "./Polaroid"
import Power01 from "./Power01"
import Power02 from "./Power02"
import Power03 from "./Power03"
import PresentationChart01 from "./PresentationChart01"
import PresentationChart02 from "./PresentationChart02"
import PresentationChart03 from "./PresentationChart03"
import Printer from "./Printer"
import PuzzlePiece01 from "./PuzzlePiece01"
import PuzzlePiece02 from "./PuzzlePiece02"
import QrCode01 from "./QrCode01"
import QrCode02 from "./QrCode02"
import ReceiptCheck from "./ReceiptCheck"
import Receipt from "./Receipt"
import Recording01 from "./Recording01"
import Recording02 from "./Recording02"
import Recording03 from "./Recording03"
import Reflect01 from "./Reflect01"
import Reflect02 from "./Reflect02"
import RefreshCcw01 from "./RefreshCcw01"
import RefreshCcw02 from "./RefreshCcw02"
import RefreshCcw03 from "./RefreshCcw03"
import RefreshCcw04 from "./RefreshCcw04"
import RefreshCcw05 from "./RefreshCcw05"
import RefreshCw01 from "./RefreshCw01"
import RefreshCw02 from "./RefreshCw02"
import RefreshCw03 from "./RefreshCw03"
import RefreshCw04 from "./RefreshCw04"
import RefreshCw05 from "./RefreshCw05"
import Repeat01 from "./Repeat01"
import Repeat02 from "./Repeat02"
import Repeat03 from "./Repeat03"
import Repeat04 from "./Repeat04"
import ReverseLeft from "./ReverseLeft"
import ReverseRight from "./ReverseRight"
import RightIndent01 from "./RightIndent01"
import RightIndent02 from "./RightIndent02"
import Rocket01 from "./Rocket01"
import Rocket02 from "./Rocket02"
import RollerBrush from "./RollerBrush"
import Route from "./Route"
import Rows01 from "./Rows01"
import Rows02 from "./Rows02"
import Rows03 from "./Rows03"
import Rss01 from "./Rss01"
import Rss02 from "./Rss02"
import Ruler from "./Ruler"
import Safe from "./Safe"
import Sale01 from "./Sale01"
import Sale02 from "./Sale02"
import Sale03 from "./Sale03"
import Sale04 from "./Sale04"
import Save01 from "./Save01"
import Save02 from "./Save02"
import Save03 from "./Save03"
import Scale01 from "./Scale01"
import Scale02 from "./Scale02"
import Scale03 from "./Scale03"
import Scales01 from "./Scales01"
import Scales02 from "./Scales02"
import Scan from "./Scan"
import Scissors01 from "./Scissors01"
import Scissors02 from "./Scissors02"
import ScissorsCut01 from "./ScissorsCut01"
import ScissorsCut02 from "./ScissorsCut02"
import SearchLg from "./SearchLg"
import SearchMd from "./SearchMd"
import SearchRefraction from "./SearchRefraction"
import SearchSm from "./SearchSm"
import Send01 from "./Send01"
import Send02 from "./Send02"
import Send03 from "./Send03"
import Server01 from "./Server01"
import Server02 from "./Server02"
import Server03 from "./Server03"
import Server04 from "./Server04"
import Server05 from "./Server05"
import Server06 from "./Server06"
import Settings01 from "./Settings01"
import Settings02 from "./Settings02"
import Settings03 from "./Settings03"
import Settings04 from "./Settings04"
import Share01 from "./Share01"
import Share02 from "./Share02"
import Share03 from "./Share03"
import Share04 from "./Share04"
import Share05 from "./Share05"
import Share06 from "./Share06"
import Share07 from "./Share07"
import Shield01 from "./Shield01"
import Shield02 from "./Shield02"
import Shield03 from "./Shield03"
import ShieldDollar from "./ShieldDollar"
import ShieldOff from "./ShieldOff"
import ShieldPlus from "./ShieldPlus"
import ShieldTick from "./ShieldTick"
import ShieldZap from "./ShieldZap"
import ShoppingBag01 from "./ShoppingBag01"
import ShoppingBag02 from "./ShoppingBag02"
import ShoppingBag03 from "./ShoppingBag03"
import ShoppingCart01 from "./ShoppingCart01"
import ShoppingCart02 from "./ShoppingCart02"
import ShoppingCart03 from "./ShoppingCart03"
import Shuffle01 from "./Shuffle01"
import Shuffle02 from "./Shuffle02"
import Signal01 from "./Signal01"
import Signal02 from "./Signal02"
import Signal03 from "./Signal03"
import Simcard from "./Simcard"
import Skew from "./Skew"
import SkipBack from "./SkipBack"
import SkipForward from "./SkipForward"
import SlashCircle01 from "./SlashCircle01"
import SlashCircle02 from "./SlashCircle02"
import SlashDivider from "./SlashDivider"
import SlashOctagon from "./SlashOctagon"
import Sliders01 from "./Sliders01"
import Sliders02 from "./Sliders02"
import Sliders03 from "./Sliders03"
import Sliders04 from "./Sliders04"
import Snowflake01 from "./Snowflake01"
import Snowflake02 from "./Snowflake02"
import SpacingHeight01 from "./SpacingHeight01"
import SpacingHeight02 from "./SpacingHeight02"
import SpacingWidth01 from "./SpacingWidth01"
import SpacingWidth02 from "./SpacingWidth02"
import Speaker01 from "./Speaker01"
import Speaker02 from "./Speaker02"
import Speaker03 from "./Speaker03"
import Speedometer01 from "./Speedometer01"
import Speedometer02 from "./Speedometer02"
import Speedometer03 from "./Speedometer03"
import Speedometer04 from "./Speedometer04"
import Square from "./Square"
import Stand from "./Stand"
import Star01 from "./Star01"
import Star02 from "./Star02"
import Star03 from "./Star03"
import Star04 from "./Star04"
import Star05 from "./Star05"
import Star06 from "./Star06"
import Star07 from "./Star07"
import Stars01 from "./Stars01"
import Stars02 from "./Stars02"
import Stars03 from "./Stars03"
import StickerCircle from "./StickerCircle"
import StickerSquare from "./StickerSquare"
import StopCircle from "./StopCircle"
import StopSquare from "./StopSquare"
import Stop from "./Stop"
import Strikethrough01 from "./Strikethrough01"
import Strikethrough02 from "./Strikethrough02"
import StrikethroughSquare from "./StrikethroughSquare"
import Subscript from "./Subscript"
import SunSetting01 from "./SunSetting01"
import SunSetting02 from "./SunSetting02"
import SunSetting03 from "./SunSetting03"
import Sun from "./Sun"
import Sunrise from "./Sunrise"
import Sunset from "./Sunset"
import SwitchHorizontal01 from "./SwitchHorizontal01"
import SwitchHorizontal02 from "./SwitchHorizontal02"
import SwitchVertical01 from "./SwitchVertical01"
import SwitchVertical02 from "./SwitchVertical02"
import Table from "./Table"
import Tablet01 from "./Tablet01"
import Tablet02 from "./Tablet02"
import Tag01 from "./Tag01"
import Tag02 from "./Tag02"
import Tag03 from "./Tag03"
import Target01 from "./Target01"
import Target02 from "./Target02"
import Target03 from "./Target03"
import Target04 from "./Target04"
import Target05 from "./Target05"
import Telescope from "./Telescope"
import TerminalBrowser from "./TerminalBrowser"
import TerminalCircle from "./TerminalCircle"
import TerminalSquare from "./TerminalSquare"
import Terminal from "./Terminal"
import TextInput from "./TextInput"
import Thermometer01 from "./Thermometer01"
import Thermometer02 from "./Thermometer02"
import Thermometer03 from "./Thermometer03"
import ThermometerCold from "./ThermometerCold"
import ThermometerWarm from "./ThermometerWarm"
import ThumbsDown from "./ThumbsDown"
import ThumbsUp from "./ThumbsUp"
import Ticket01 from "./Ticket01"
import Ticket02 from "./Ticket02"
import Toggle01Left from "./Toggle01Left"
import Toggle01Right from "./Toggle01Right"
import Toggle02Left from "./Toggle02Left"
import Toggle02Right from "./Toggle02Right"
import Toggle03Left from "./Toggle03Left"
import Toggle03Right from "./Toggle03Right"
import Tool01 from "./Tool01"
import Tool02 from "./Tool02"
import Train from "./Train"
import Tram from "./Tram"
import Transform from "./Transform"
import Translate01 from "./Translate01"
import Translate02 from "./Translate02"
import Trash01 from "./Trash01"
import Trash02 from "./Trash02"
import Trash03 from "./Trash03"
import Trash04 from "./Trash04"
import TrendDown01 from "./TrendDown01"
import TrendDown02 from "./TrendDown02"
import TrendUp01 from "./TrendUp01"
import TrendUp02 from "./TrendUp02"
import Triangle from "./Triangle"
import Trophy01 from "./Trophy01"
import Trophy02 from "./Trophy02"
import Truck01 from "./Truck01"
import Truck02 from "./Truck02"
import Tv01 from "./Tv01"
import Tv02 from "./Tv02"
import Tv03 from "./Tv03"
import Type01 from "./Type01"
import Type02 from "./Type02"
import TypeSquare from "./TypeSquare"
import TypeStrikethrough01 from "./TypeStrikethrough01"
import TypeStrikethrough02 from "./TypeStrikethrough02"
import Umbrella01 from "./Umbrella01"
import Umbrella02 from "./Umbrella02"
import Umbrella03 from "./Umbrella03"
import Underline01 from "./Underline01"
import Underline02 from "./Underline02"
import UnderlineSquare from "./UnderlineSquare"
import Upload01 from "./Upload01"
import Upload02 from "./Upload02"
import Upload03 from "./Upload03"
import Upload04 from "./Upload04"
import UploadCloud01 from "./UploadCloud01"
import UploadCloud02 from "./UploadCloud02"
import UsbFlashDrive from "./UsbFlashDrive"
import User01 from "./User01"
import User02 from "./User02"
import User03 from "./User03"
import UserCheck01 from "./UserCheck01"
import UserCheck02 from "./UserCheck02"
import UserCircle from "./UserCircle"
import UserDown01 from "./UserDown01"
import UserDown02 from "./UserDown02"
import UserEdit from "./UserEdit"
import UserLeft01 from "./UserLeft01"
import UserLeft02 from "./UserLeft02"
import UserMinus01 from "./UserMinus01"
import UserMinus02 from "./UserMinus02"
import UserPlus01 from "./UserPlus01"
import UserPlus02 from "./UserPlus02"
import UserRight01 from "./UserRight01"
import UserRight02 from "./UserRight02"
import UserSquare from "./UserSquare"
import UserUp01 from "./UserUp01"
import UserUp02 from "./UserUp02"
import UserX01 from "./UserX01"
import UserX02 from "./UserX02"
import Users01 from "./Users01"
import Users02 from "./Users02"
import Users03 from "./Users03"
import UsersCheck from "./UsersCheck"
import UsersDown from "./UsersDown"
import UsersEdit from "./UsersEdit"
import UsersLeft from "./UsersLeft"
import UsersMinus from "./UsersMinus"
import UsersPlus from "./UsersPlus"
import UsersRight from "./UsersRight"
import UsersUp from "./UsersUp"
import UsersX from "./UsersX"
import Variable from "./Variable"
import VideoRecorderOff from "./VideoRecorderOff"
import VideoRecorder from "./VideoRecorder"
import Virus from "./Virus"
import Voicemail from "./Voicemail"
import VolumeMax from "./VolumeMax"
import VolumeMin from "./VolumeMin"
import VolumeMinus from "./VolumeMinus"
import VolumePlus from "./VolumePlus"
import VolumeX from "./VolumeX"
import Wallet01 from "./Wallet01"
import Wallet02 from "./Wallet02"
import Wallet03 from "./Wallet03"
import Wallet04 from "./Wallet04"
import Wallet05 from "./Wallet05"
import WatchCircle from "./WatchCircle"
import WatchSquare from "./WatchSquare"
import Waves from "./Waves"
import Webcam01 from "./Webcam01"
import Webcam02 from "./Webcam02"
import WifiOff from "./WifiOff"
import Wifi from "./Wifi"
import Wind01 from "./Wind01"
import Wind02 from "./Wind02"
import Wind03 from "./Wind03"
import XCircle from "./XCircle"
import XClose from "./XClose"
import XSquare from "./XSquare"
import X from "./X"
import Youtube from "./Youtube"
import ZapCircle from "./ZapCircle"
import ZapFast from "./ZapFast"
import ZapOff from "./ZapOff"
import ZapSquare from "./ZapSquare"
import Zap from "./Zap"
import ZoomIn from "./ZoomIn"
import ZoomOut from "./ZoomOut"

export type IconName =
  | "ActivityHeart"
  | "Activity"
  | "Airplay"
  | "Airpods"
  | "AlarmClockCheck"
  | "AlarmClockMinus"
  | "AlarmClockOff"
  | "AlarmClockPlus"
  | "AlarmClock"
  | "AlertCircle"
  | "AlertHexagon"
  | "AlertOctagon"
  | "AlertSquare"
  | "AlertTriangle"
  | "AlignBottom01"
  | "AlignBottom02"
  | "AlignCenter"
  | "AlignHorizontalCentre01"
  | "AlignHorizontalCentre02"
  | "AlignJustify"
  | "AlignLeft01"
  | "AlignLeft02"
  | "AlignLeft"
  | "AlignRight01"
  | "AlignRight02"
  | "AlignRight"
  | "AlignTop01"
  | "AlignTop02"
  | "AlignVerticalCenter01"
  | "AlignVerticalCenter02"
  | "Anchor"
  | "AnnotationAlert"
  | "AnnotationCheck"
  | "AnnotationDots"
  | "AnnotationHeart"
  | "AnnotationInfo"
  | "AnnotationPlus"
  | "AnnotationQuestion"
  | "AnnotationX"
  | "Annotation"
  | "Announcement01"
  | "Announcement02"
  | "Announcement03"
  | "Archive"
  | "ArrowBlockDown"
  | "ArrowBlockLeft"
  | "ArrowBlockRight"
  | "ArrowBlockUp"
  | "ArrowCircleBrokenDownLeft"
  | "ArrowCircleBrokenDownRight"
  | "ArrowCircleBrokenDown"
  | "ArrowCircleBrokenLeft"
  | "ArrowCircleBrokenRight"
  | "ArrowCircleBrokenUpLeft"
  | "ArrowCircleBrokenUpRight"
  | "ArrowCircleBrokenUp"
  | "ArrowCircleDownLeft"
  | "ArrowCircleDownRight"
  | "ArrowCircleDown"
  | "ArrowCircleLeft"
  | "ArrowCircleRight"
  | "ArrowCircleUpLeft"
  | "ArrowCircleUpRight"
  | "ArrowCircleUp"
  | "ArrowDownLeft"
  | "ArrowDownRight"
  | "ArrowDown"
  | "ArrowLeft"
  | "ArrowNarrowDownLeft"
  | "ArrowNarrowDownRight"
  | "ArrowNarrowDown"
  | "ArrowNarrowLeft"
  | "ArrowNarrowRight"
  | "ArrowNarrowUpLeft"
  | "ArrowNarrowUpRight"
  | "ArrowNarrowUp"
  | "ArrowRight"
  | "ArrowSquareDownLeft"
  | "ArrowSquareDownRight"
  | "ArrowSquareDown"
  | "ArrowSquareLeft"
  | "ArrowSquareRight"
  | "ArrowSquareUpLeft"
  | "ArrowSquareUpRight"
  | "ArrowSquareUp"
  | "ArrowUpLeft"
  | "ArrowUpRight"
  | "ArrowUp"
  | "ArrowsDown"
  | "ArrowsLeft"
  | "ArrowsRight"
  | "ArrowsTriangle"
  | "ArrowsUp"
  | "Asterisk01"
  | "Asterisk02"
  | "AtSign"
  | "Atom01"
  | "Atom02"
  | "Attachment01"
  | "Attachment02"
  | "Award01"
  | "Award02"
  | "Award03"
  | "Award04"
  | "Award05"
  | "Backpack"
  | "BankNote01"
  | "BankNote02"
  | "BankNote03"
  | "Bank"
  | "BarChart01"
  | "BarChart02"
  | "BarChart03"
  | "BarChart04"
  | "BarChart05"
  | "BarChart06"
  | "BarChart07"
  | "BarChart08"
  | "BarChart09"
  | "BarChart10"
  | "BarChart11"
  | "BarChart12"
  | "BarChartCircle01"
  | "BarChartCircle02"
  | "BarChartCircle03"
  | "BarChartSquare01"
  | "BarChartSquare02"
  | "BarChartSquare03"
  | "BarChartSquareDown"
  | "BarChartSquareMinus"
  | "BarChartSquarePlus"
  | "BarChartSquareUp"
  | "BarLineChart"
  | "BatteryCharging01"
  | "BatteryCharging02"
  | "BatteryEmpty"
  | "BatteryFull"
  | "BatteryLow"
  | "BatteryMid"
  | "Beaker01"
  | "Beaker02"
  | "Bell01"
  | "Bell02"
  | "Bell03"
  | "Bell04"
  | "BellMinus"
  | "BellOff01"
  | "BellOff02"
  | "BellOff03"
  | "BellPlus"
  | "BellRinging01"
  | "BellRinging02"
  | "BellRinging03"
  | "BellRinging04"
  | "BezierCurve01"
  | "BezierCurve02"
  | "BezierCurve03"
  | "BluetoothConnect"
  | "BluetoothOff"
  | "BluetoothOn"
  | "BluetoothSignal"
  | "Bold01"
  | "Bold02"
  | "BoldSquare"
  | "BookClosed"
  | "BookOpen01"
  | "BookOpen02"
  | "BookmarkAdd"
  | "BookmarkCheck"
  | "BookmarkMinus"
  | "BookmarkX"
  | "Bookmark"
  | "Box"
  | "BracketsCheck"
  | "BracketsEllipses"
  | "BracketsMinus"
  | "BracketsPlus"
  | "BracketsSlash"
  | "BracketsX"
  | "Brackets"
  | "Briefcase01"
  | "Briefcase02"
  | "Browser"
  | "Brush01"
  | "Brush02"
  | "Brush03"
  | "Building01"
  | "Building02"
  | "Building03"
  | "Building04"
  | "Building05"
  | "Building06"
  | "Building07"
  | "Building08"
  | "Bus"
  | "Calculator"
  | "CalendarCheck01"
  | "CalendarCheck02"
  | "CalendarDate"
  | "CalendarHeart01"
  | "CalendarHeart02"
  | "CalendarMinus01"
  | "CalendarMinus02"
  | "CalendarPlus01"
  | "CalendarPlus02"
  | "Calendar"
  | "Camera01"
  | "Camera02"
  | "Camera03"
  | "CameraLens"
  | "CameraOff"
  | "CameraPlus"
  | "Car01"
  | "Car02"
  | "Certificate01"
  | "Certificate02"
  | "ChartBreakoutCircle"
  | "ChartBreakoutSquare"
  | "CheckCircleBroken"
  | "CheckCircle"
  | "CheckDone01"
  | "CheckDone02"
  | "CheckHeart"
  | "CheckSquareBroken"
  | "CheckSquare"
  | "CheckVerified01"
  | "CheckVerified02"
  | "CheckVerified03"
  | "Check"
  | "ChevronDownDouble"
  | "ChevronDown"
  | "ChevronLeftDouble"
  | "ChevronLeft"
  | "ChevronRightDouble"
  | "ChevronRight"
  | "ChevronSelectorHorizontal"
  | "ChevronSelectorVertical"
  | "ChevronUpDouble"
  | "ChevronUp"
  | "ChromeCast"
  | "CircleCut"
  | "Circle"
  | "Clapperboard"
  | "ClipboardAttachment"
  | "ClipboardCheck"
  | "ClipboardDownload"
  | "ClipboardMinus"
  | "ClipboardPlus"
  | "ClipboardX"
  | "Clipboard"
  | "ClockCheck"
  | "ClockFastForward"
  | "ClockPlus"
  | "ClockRefresh"
  | "ClockRewind"
  | "ClockSnooze"
  | "ClockStopwatch"
  | "Clock"
  | "Cloud01"
  | "Cloud02"
  | "Cloud03"
  | "CloudBlank01"
  | "CloudBlank02"
  | "CloudLightning"
  | "CloudMoon"
  | "CloudOff"
  | "CloudRaining01"
  | "CloudRaining02"
  | "CloudRaining03"
  | "CloudRaining04"
  | "CloudRaining05"
  | "CloudRaining06"
  | "CloudSnowing01"
  | "CloudSnowing02"
  | "CloudSun01"
  | "CloudSun02"
  | "CloudSun03"
  | "Code01"
  | "Code02"
  | "CodeBrowser"
  | "CodeCircle01"
  | "CodeCircle02"
  | "CodeCircle03"
  | "CodeSnippet01"
  | "CodeSnippet02"
  | "CodeSquare01"
  | "CodeSquare02"
  | "Codepen"
  | "Coins01"
  | "Coins02"
  | "Coins03"
  | "Coins04"
  | "CoinsHand"
  | "CoinsStacked01"
  | "CoinsStacked02"
  | "CoinsStacked03"
  | "CoinsStacked04"
  | "CoinsSwap01"
  | "CoinsSwap02"
  | "Colors2"
  | "Colors"
  | "Columns01"
  | "Columns02"
  | "Columns03"
  | "Command"
  | "Compass01"
  | "Compass02"
  | "Compass03"
  | "Compass"
  | "Container"
  | "Contrast01"
  | "Contrast02"
  | "Contrast03"
  | "Copy01"
  | "Copy02"
  | "Copy03"
  | "Copy04"
  | "Copy05"
  | "Copy06"
  | "Copy07"
  | "CornerDownLeft"
  | "CornerDownRight"
  | "CornerLeftDown"
  | "CornerLeftUp"
  | "CornerRightDown"
  | "CornerRightUp"
  | "CornerUpLeft"
  | "CornerUpRight"
  | "CpuChip01"
  | "CpuChip02"
  | "CreditCard01"
  | "CreditCard02"
  | "CreditCardCheck"
  | "CreditCardDown"
  | "CreditCardDownload"
  | "CreditCardEdit"
  | "CreditCardLock"
  | "CreditCardMinus"
  | "CreditCardPlus"
  | "CreditCardRefresh"
  | "CreditCardSearch"
  | "CreditCardShield"
  | "CreditCardUp"
  | "CreditCardUpload"
  | "CreditCardX"
  | "Crop01"
  | "Crop02"
  | "Cryptocurrency01"
  | "Cryptocurrency02"
  | "Cryptocurrency03"
  | "Cryptocurrency04"
  | "Cube01"
  | "Cube02"
  | "Cube03"
  | "Cube04"
  | "CubeOutline"
  | "CurrencyBitcoinCircle"
  | "CurrencyBitcoin"
  | "CurrencyDollarCircle"
  | "CurrencyDollar"
  | "CurrencyEthereumCircle"
  | "CurrencyEthereum"
  | "CurrencyEuroCircle"
  | "CurrencyEuro"
  | "CurrencyPoundCircle"
  | "CurrencyPound"
  | "CurrencyRubleCircle"
  | "CurrencyRuble"
  | "CurrencyRupeeCircle"
  | "CurrencyRupee"
  | "CurrencyYenCircle"
  | "CurrencyYen"
  | "Cursor01"
  | "Cursor02"
  | "Cursor03"
  | "Cursor04"
  | "CursorBox"
  | "CursorClick01"
  | "CursorClick02"
  | "Data"
  | "Database01"
  | "Database02"
  | "Database03"
  | "Dataflow01"
  | "Dataflow02"
  | "Dataflow03"
  | "Dataflow04"
  | "Delete"
  | "Diamond01"
  | "Diamond02"
  | "Dice1"
  | "Dice2"
  | "Dice3"
  | "Dice4"
  | "Dice5"
  | "Dice6"
  | "Disc01"
  | "Disc02"
  | "DistributeSpacingHorizontal"
  | "DistributeSpacingVertical"
  | "Divide01"
  | "Divide02"
  | "Divide03"
  | "Divider"
  | "Dotpoints01"
  | "Dotpoints02"
  | "DotsGrid"
  | "DotsHorizontal"
  | "DotsVertical"
  | "Download01"
  | "Download02"
  | "Download03"
  | "Download04"
  | "DownloadCloud01"
  | "DownloadCloud02"
  | "Drop"
  | "Droplets01"
  | "Droplets02"
  | "Droplets03"
  | "Dropper"
  | "Edit01"
  | "Edit02"
  | "Edit03"
  | "Edit04"
  | "Edit05"
  | "EqualNot"
  | "Equal"
  | "Eraser"
  | "Expand01"
  | "Expand02"
  | "Expand03"
  | "Expand04"
  | "Expand05"
  | "Expand06"
  | "EyeOff"
  | "Eye"
  | "FaceContent"
  | "FaceFrown"
  | "FaceHappy"
  | "FaceIdSquare"
  | "FaceId"
  | "FaceNeutral"
  | "FaceSad"
  | "FaceSmile"
  | "FaceWink"
  | "FastBackward"
  | "FastForward"
  | "Feather"
  | "Figma"
  | "File01"
  | "File02"
  | "File03"
  | "File04"
  | "File05"
  | "File06"
  | "File07"
  | "FileAttachment01"
  | "FileAttachment02"
  | "FileAttachment03"
  | "FileAttachment04"
  | "FileAttachment05"
  | "FileCheck01"
  | "FileCheck02"
  | "FileCheck03"
  | "FileCode01"
  | "FileCode02"
  | "FileDownload01"
  | "FileDownload02"
  | "FileDownload03"
  | "FileHeart01"
  | "FileHeart02"
  | "FileHeart03"
  | "FileLock01"
  | "FileLock02"
  | "FileLock03"
  | "FileMinus01"
  | "FileMinus02"
  | "FileMinus03"
  | "FilePlus01"
  | "FilePlus02"
  | "FilePlus03"
  | "FileQuestion01"
  | "FileQuestion02"
  | "FileQuestion03"
  | "FileSearch01"
  | "FileSearch02"
  | "FileSearch03"
  | "FileShield01"
  | "FileShield02"
  | "FileShield03"
  | "FileX01"
  | "FileX02"
  | "FileX03"
  | "Film01"
  | "Film02"
  | "Film03"
  | "FilterFunnel01"
  | "FilterFunnel02"
  | "FilterLines"
  | "Fingerprint01"
  | "Fingerprint02"
  | "Fingerprint03"
  | "Fingerprint04"
  | "Flag01"
  | "Flag02"
  | "Flag03"
  | "Flag04"
  | "Flag05"
  | "Flag06"
  | "FlashOff"
  | "Flash"
  | "FlexAlignBottom"
  | "FlexAlignLeft"
  | "FlexAlignRight"
  | "FlexAlignTop"
  | "FlipBackward"
  | "FlipForward"
  | "FolderCheck"
  | "FolderClosed"
  | "FolderCode"
  | "FolderDownload"
  | "FolderLock"
  | "FolderMinus"
  | "FolderPlus"
  | "FolderQuestion"
  | "FolderSearch"
  | "FolderShield"
  | "FolderX"
  | "Folder"
  | "Framer"
  | "GamingPad01"
  | "GamingPad02"
  | "Gift01"
  | "Gift02"
  | "GitBranch01"
  | "GitBranch02"
  | "GitCommit"
  | "GitMerge"
  | "GitPullRequest"
  | "Glasses01"
  | "Glasses02"
  | "Globe01"
  | "Globe02"
  | "Globe03"
  | "Globe04"
  | "Globe05"
  | "Globe06"
  | "GlobeSlated01"
  | "GlobeSlated02"
  | "GoogleChrome"
  | "GraduationHat01"
  | "GraduationHat02"
  | "Grid01"
  | "Grid02"
  | "Grid03"
  | "GridDotsBlank"
  | "GridDotsBottom"
  | "GridDotsHorizontalCenter"
  | "GridDotsLeft"
  | "GridDotsOuter"
  | "GridDotsRight"
  | "GridDotsTop"
  | "GridDotsVerticalCenter"
  | "Hand"
  | "HardDrive"
  | "Hash01"
  | "Hash02"
  | "Heading01"
  | "Heading02"
  | "HeadingSquare"
  | "Headphones01"
  | "Headphones02"
  | "HeartCircle"
  | "HeartHand"
  | "HeartHexagon"
  | "HeartOctagon"
  | "HeartRounded"
  | "HeartSquare"
  | "Heart"
  | "Hearts"
  | "HelpCircle"
  | "HelpHexagon"
  | "HelpOctagon"
  | "HelpSquare"
  | "Hexagon01"
  | "Hexagon02"
  | "Home01"
  | "Home02"
  | "Home03"
  | "Home04"
  | "Home05"
  | "HomeLine"
  | "HomeSmile"
  | "HorizontalBarChart01"
  | "HorizontalBarChart02"
  | "HorizontalBarChart03"
  | "Hourglass01"
  | "Hourglass02"
  | "Hourglass03"
  | "Hurricane01"
  | "Hurricane02"
  | "Hurricane03"
  | "Image01"
  | "Image02"
  | "Image03"
  | "Image04"
  | "Image05"
  | "ImageCheck"
  | "ImageDown"
  | "ImageIndentLeft"
  | "ImageIndentRight"
  | "ImageLeft"
  | "ImagePlus"
  | "ImageRight"
  | "ImageUp"
  | "ImageUserCheck"
  | "ImageUserDown"
  | "ImageUserLeft"
  | "ImageUserPlus"
  | "ImageUserRight"
  | "ImageUserUp"
  | "ImageUserX"
  | "ImageUser"
  | "ImageX"
  | "Inbox01"
  | "Inbox02"
  | "Infinity"
  | "InfoCircle"
  | "InfoHexagon"
  | "InfoOctagon"
  | "InfoSquare"
  | "IntersectCircle"
  | "IntersectSquare"
  | "Italic01"
  | "Italic02"
  | "ItalicSquare"
  | "Key01"
  | "Key02"
  | "Keyboard01"
  | "Keyboard02"
  | "Laptop01"
  | "Laptop02"
  | "LayerSingle"
  | "LayersThree01"
  | "LayersThree02"
  | "LayersTwo01"
  | "LayersTwo02"
  | "LayoutAlt01"
  | "LayoutAlt02"
  | "LayoutAlt03"
  | "LayoutAlt04"
  | "LayoutBottom"
  | "LayoutGrid01"
  | "LayoutGrid02"
  | "LayoutLeft"
  | "LayoutRight"
  | "LayoutTop"
  | "LeftIndent01"
  | "LeftIndent02"
  | "LetterSpacing01"
  | "LetterSpacing02"
  | "LifeBuoy01"
  | "LifeBuoy02"
  | "Lightbulb01"
  | "Lightbulb02"
  | "Lightbulb03"
  | "Lightbulb04"
  | "Lightbulb05"
  | "Lightning01"
  | "Lightning02"
  | "LineChartDown01"
  | "LineChartDown02"
  | "LineChartDown03"
  | "LineChartDown04"
  | "LineChartDown05"
  | "LineChartUp01"
  | "LineChartUp02"
  | "LineChartUp03"
  | "LineChartUp04"
  | "LineChartUp05"
  | "LineHeight"
  | "Link01"
  | "Link02"
  | "Link03"
  | "Link04"
  | "Link05"
  | "LinkBroken01"
  | "LinkBroken02"
  | "LinkExternal01"
  | "LinkExternal02"
  | "List"
  | "Loading01"
  | "Loading02"
  | "Loading03"
  | "Lock01"
  | "Lock02"
  | "Lock03"
  | "Lock04"
  | "LockKeyholeCircle"
  | "LockKeyholeSquare"
  | "LockUnlocked01"
  | "LockUnlocked02"
  | "LockUnlocked03"
  | "LockUnlocked04"
  | "LogIn01"
  | "LogIn02"
  | "LogIn03"
  | "LogIn04"
  | "LogOut01"
  | "LogOut02"
  | "LogOut03"
  | "LogOut04"
  | "Luggage01"
  | "Luggage02"
  | "Luggage03"
  | "MagicWand01"
  | "MagicWand02"
  | "Mail01"
  | "Mail02"
  | "Mail03"
  | "Mail04"
  | "Mail05"
  | "Map01"
  | "Map02"
  | "Mark"
  | "MarkerPin01"
  | "MarkerPin02"
  | "MarkerPin03"
  | "MarkerPin04"
  | "MarkerPin05"
  | "MarkerPin06"
  | "Maximize01"
  | "Maximize02"
  | "MedicalCircle"
  | "MedicalCross"
  | "MedicalSquare"
  | "Menu01"
  | "Menu02"
  | "Menu03"
  | "Menu04"
  | "Menu05"
  | "MessageAlertCircle"
  | "MessageAlertSquare"
  | "MessageChatCircle"
  | "MessageChatSquare"
  | "MessageCheckCircle"
  | "MessageCheckSquare"
  | "MessageCircle01"
  | "MessageCircle02"
  | "MessageDotsCircle"
  | "MessageDotsSquare"
  | "MessageHeartCircle"
  | "MessageHeartSquare"
  | "MessageNotificationCircle"
  | "MessageNotificationSquare"
  | "MessagePlusCircle"
  | "MessagePlusSquare"
  | "MessageQuestionCircle"
  | "MessageQuestionSquare"
  | "MessageSmileCircle"
  | "MessageSmileSquare"
  | "MessageSquare01"
  | "MessageSquare02"
  | "MessageTextCircle01"
  | "MessageTextCircle02"
  | "MessageTextSquare01"
  | "MessageTextSquare02"
  | "MessageXCircle"
  | "MessageXSquare"
  | "Microphone01"
  | "Microphone02"
  | "MicrophoneOff01"
  | "MicrophoneOff02"
  | "Microscope"
  | "Minimize01"
  | "Minimize02"
  | "MinusCircle"
  | "MinusSquare"
  | "Minus"
  | "Modem01"
  | "Modem02"
  | "Monitor01"
  | "Monitor02"
  | "Monitor03"
  | "Monitor04"
  | "Monitor05"
  | "Moon01"
  | "Moon02"
  | "MoonEclipse"
  | "MoonStar"
  | "Mouse"
  | "Move"
  | "MusicNote01"
  | "MusicNote02"
  | "MusicNotePlus"
  | "NavigationPointer01"
  | "NavigationPointer02"
  | "NavigationPointerOff01"
  | "NavigationPointerOff02"
  | "NotificationBox"
  | "NotificationMessage"
  | "NotificationText"
  | "Octagon"
  | "PackageCheck"
  | "PackageMinus"
  | "PackagePlus"
  | "PackageSearch"
  | "PackageX"
  | "Package"
  | "PaintPour"
  | "Paint"
  | "Palette"
  | "Paperclip"
  | "ParagraphSpacing"
  | "ParagraphWrap"
  | "PasscodeLock"
  | "Passcode"
  | "Passport"
  | "PauseCircle"
  | "PauseSquare"
  | "PenTool01"
  | "PenTool02"
  | "PenToolMinus"
  | "PenToolPlus"
  | "Pencil01"
  | "Pencil02"
  | "PencilLine"
  | "Pentagon"
  | "Percent01"
  | "Percent02"
  | "Percent03"
  | "Perspective01"
  | "Perspective02"
  | "Phone01"
  | "Phone02"
  | "PhoneCall01"
  | "PhoneCall02"
  | "PhoneHangUp"
  | "PhoneIncoming01"
  | "PhoneIncoming02"
  | "PhoneOutgoing01"
  | "PhoneOutgoing02"
  | "PhonePause"
  | "PhonePlus"
  | "PhoneX"
  | "Phone"
  | "PieChart01"
  | "PieChart02"
  | "PieChart03"
  | "PieChart04"
  | "PiggyBank01"
  | "PiggyBank02"
  | "Pilcrow01"
  | "Pilcrow02"
  | "PilcrowSquare"
  | "Pin01"
  | "Pin02"
  | "Placeholder"
  | "Plane"
  | "PlayCircle"
  | "PlaySquare"
  | "Play"
  | "PlusCircle"
  | "PlusSquare"
  | "Plus"
  | "Podcast"
  | "Polaroid"
  | "Power01"
  | "Power02"
  | "Power03"
  | "PresentationChart01"
  | "PresentationChart02"
  | "PresentationChart03"
  | "Printer"
  | "PuzzlePiece01"
  | "PuzzlePiece02"
  | "QrCode01"
  | "QrCode02"
  | "ReceiptCheck"
  | "Receipt"
  | "Recording01"
  | "Recording02"
  | "Recording03"
  | "Reflect01"
  | "Reflect02"
  | "RefreshCcw01"
  | "RefreshCcw02"
  | "RefreshCcw03"
  | "RefreshCcw04"
  | "RefreshCcw05"
  | "RefreshCw01"
  | "RefreshCw02"
  | "RefreshCw03"
  | "RefreshCw04"
  | "RefreshCw05"
  | "Repeat01"
  | "Repeat02"
  | "Repeat03"
  | "Repeat04"
  | "ReverseLeft"
  | "ReverseRight"
  | "RightIndent01"
  | "RightIndent02"
  | "Rocket01"
  | "Rocket02"
  | "RollerBrush"
  | "Route"
  | "Rows01"
  | "Rows02"
  | "Rows03"
  | "Rss01"
  | "Rss02"
  | "Ruler"
  | "Safe"
  | "Sale01"
  | "Sale02"
  | "Sale03"
  | "Sale04"
  | "Save01"
  | "Save02"
  | "Save03"
  | "Scale01"
  | "Scale02"
  | "Scale03"
  | "Scales01"
  | "Scales02"
  | "Scan"
  | "Scissors01"
  | "Scissors02"
  | "ScissorsCut01"
  | "ScissorsCut02"
  | "SearchLg"
  | "SearchMd"
  | "SearchRefraction"
  | "SearchSm"
  | "Send01"
  | "Send02"
  | "Send03"
  | "Server01"
  | "Server02"
  | "Server03"
  | "Server04"
  | "Server05"
  | "Server06"
  | "Settings01"
  | "Settings02"
  | "Settings03"
  | "Settings04"
  | "Share01"
  | "Share02"
  | "Share03"
  | "Share04"
  | "Share05"
  | "Share06"
  | "Share07"
  | "Shield01"
  | "Shield02"
  | "Shield03"
  | "ShieldDollar"
  | "ShieldOff"
  | "ShieldPlus"
  | "ShieldTick"
  | "ShieldZap"
  | "ShoppingBag01"
  | "ShoppingBag02"
  | "ShoppingBag03"
  | "ShoppingCart01"
  | "ShoppingCart02"
  | "ShoppingCart03"
  | "Shuffle01"
  | "Shuffle02"
  | "Signal01"
  | "Signal02"
  | "Signal03"
  | "Simcard"
  | "Skew"
  | "SkipBack"
  | "SkipForward"
  | "SlashCircle01"
  | "SlashCircle02"
  | "SlashDivider"
  | "SlashOctagon"
  | "Sliders01"
  | "Sliders02"
  | "Sliders03"
  | "Sliders04"
  | "Snowflake01"
  | "Snowflake02"
  | "SpacingHeight01"
  | "SpacingHeight02"
  | "SpacingWidth01"
  | "SpacingWidth02"
  | "Speaker01"
  | "Speaker02"
  | "Speaker03"
  | "Speedometer01"
  | "Speedometer02"
  | "Speedometer03"
  | "Speedometer04"
  | "Square"
  | "Stand"
  | "Star01"
  | "Star02"
  | "Star03"
  | "Star04"
  | "Star05"
  | "Star06"
  | "Star07"
  | "Stars01"
  | "Stars02"
  | "Stars03"
  | "StickerCircle"
  | "StickerSquare"
  | "StopCircle"
  | "StopSquare"
  | "Stop"
  | "Strikethrough01"
  | "Strikethrough02"
  | "StrikethroughSquare"
  | "Subscript"
  | "SunSetting01"
  | "SunSetting02"
  | "SunSetting03"
  | "Sun"
  | "Sunrise"
  | "Sunset"
  | "SwitchHorizontal01"
  | "SwitchHorizontal02"
  | "SwitchVertical01"
  | "SwitchVertical02"
  | "Table"
  | "Tablet01"
  | "Tablet02"
  | "Tag01"
  | "Tag02"
  | "Tag03"
  | "Target01"
  | "Target02"
  | "Target03"
  | "Target04"
  | "Target05"
  | "Telescope"
  | "TerminalBrowser"
  | "TerminalCircle"
  | "TerminalSquare"
  | "Terminal"
  | "TextInput"
  | "Thermometer01"
  | "Thermometer02"
  | "Thermometer03"
  | "ThermometerCold"
  | "ThermometerWarm"
  | "ThumbsDown"
  | "ThumbsUp"
  | "Ticket01"
  | "Ticket02"
  | "Toggle01Left"
  | "Toggle01Right"
  | "Toggle02Left"
  | "Toggle02Right"
  | "Toggle03Left"
  | "Toggle03Right"
  | "Tool01"
  | "Tool02"
  | "Train"
  | "Tram"
  | "Transform"
  | "Translate01"
  | "Translate02"
  | "Trash01"
  | "Trash02"
  | "Trash03"
  | "Trash04"
  | "TrendDown01"
  | "TrendDown02"
  | "TrendUp01"
  | "TrendUp02"
  | "Triangle"
  | "Trophy01"
  | "Trophy02"
  | "Truck01"
  | "Truck02"
  | "Tv01"
  | "Tv02"
  | "Tv03"
  | "Type01"
  | "Type02"
  | "TypeSquare"
  | "TypeStrikethrough01"
  | "TypeStrikethrough02"
  | "Umbrella01"
  | "Umbrella02"
  | "Umbrella03"
  | "Underline01"
  | "Underline02"
  | "UnderlineSquare"
  | "Upload01"
  | "Upload02"
  | "Upload03"
  | "Upload04"
  | "UploadCloud01"
  | "UploadCloud02"
  | "UsbFlashDrive"
  | "User01"
  | "User02"
  | "User03"
  | "UserCheck01"
  | "UserCheck02"
  | "UserCircle"
  | "UserDown01"
  | "UserDown02"
  | "UserEdit"
  | "UserLeft01"
  | "UserLeft02"
  | "UserMinus01"
  | "UserMinus02"
  | "UserPlus01"
  | "UserPlus02"
  | "UserRight01"
  | "UserRight02"
  | "UserSquare"
  | "UserUp01"
  | "UserUp02"
  | "UserX01"
  | "UserX02"
  | "Users01"
  | "Users02"
  | "Users03"
  | "UsersCheck"
  | "UsersDown"
  | "UsersEdit"
  | "UsersLeft"
  | "UsersMinus"
  | "UsersPlus"
  | "UsersRight"
  | "UsersUp"
  | "UsersX"
  | "Variable"
  | "VideoRecorderOff"
  | "VideoRecorder"
  | "Virus"
  | "Voicemail"
  | "VolumeMax"
  | "VolumeMin"
  | "VolumeMinus"
  | "VolumePlus"
  | "VolumeX"
  | "Wallet01"
  | "Wallet02"
  | "Wallet03"
  | "Wallet04"
  | "Wallet05"
  | "WatchCircle"
  | "WatchSquare"
  | "Waves"
  | "Webcam01"
  | "Webcam02"
  | "WifiOff"
  | "Wifi"
  | "Wind01"
  | "Wind02"
  | "Wind03"
  | "XCircle"
  | "XClose"
  | "XSquare"
  | "X"
  | "Youtube"
  | "ZapCircle"
  | "ZapFast"
  | "ZapOff"
  | "ZapSquare"
  | "Zap"
  | "ZoomIn"
  | "ZoomOut"

const icons = {
  ActivityHeart,
  Activity,
  Airplay,
  Airpods,
  AlarmClockCheck,
  AlarmClockMinus,
  AlarmClockOff,
  AlarmClockPlus,
  AlarmClock,
  AlertCircle,
  AlertHexagon,
  AlertOctagon,
  AlertSquare,
  AlertTriangle,
  AlignBottom01,
  AlignBottom02,
  AlignCenter,
  AlignHorizontalCentre01,
  AlignHorizontalCentre02,
  AlignJustify,
  AlignLeft01,
  AlignLeft02,
  AlignLeft,
  AlignRight01,
  AlignRight02,
  AlignRight,
  AlignTop01,
  AlignTop02,
  AlignVerticalCenter01,
  AlignVerticalCenter02,
  Anchor,
  AnnotationAlert,
  AnnotationCheck,
  AnnotationDots,
  AnnotationHeart,
  AnnotationInfo,
  AnnotationPlus,
  AnnotationQuestion,
  AnnotationX,
  Annotation,
  Announcement01,
  Announcement02,
  Announcement03,
  Archive,
  ArrowBlockDown,
  ArrowBlockLeft,
  ArrowBlockRight,
  ArrowBlockUp,
  ArrowCircleBrokenDownLeft,
  ArrowCircleBrokenDownRight,
  ArrowCircleBrokenDown,
  ArrowCircleBrokenLeft,
  ArrowCircleBrokenRight,
  ArrowCircleBrokenUpLeft,
  ArrowCircleBrokenUpRight,
  ArrowCircleBrokenUp,
  ArrowCircleDownLeft,
  ArrowCircleDownRight,
  ArrowCircleDown,
  ArrowCircleLeft,
  ArrowCircleRight,
  ArrowCircleUpLeft,
  ArrowCircleUpRight,
  ArrowCircleUp,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowDown,
  ArrowLeft,
  ArrowNarrowDownLeft,
  ArrowNarrowDownRight,
  ArrowNarrowDown,
  ArrowNarrowLeft,
  ArrowNarrowRight,
  ArrowNarrowUpLeft,
  ArrowNarrowUpRight,
  ArrowNarrowUp,
  ArrowRight,
  ArrowSquareDownLeft,
  ArrowSquareDownRight,
  ArrowSquareDown,
  ArrowSquareLeft,
  ArrowSquareRight,
  ArrowSquareUpLeft,
  ArrowSquareUpRight,
  ArrowSquareUp,
  ArrowUpLeft,
  ArrowUpRight,
  ArrowUp,
  ArrowsDown,
  ArrowsLeft,
  ArrowsRight,
  ArrowsTriangle,
  ArrowsUp,
  Asterisk01,
  Asterisk02,
  AtSign,
  Atom01,
  Atom02,
  Attachment01,
  Attachment02,
  Award01,
  Award02,
  Award03,
  Award04,
  Award05,
  Backpack,
  BankNote01,
  BankNote02,
  BankNote03,
  Bank,
  BarChart01,
  BarChart02,
  BarChart03,
  BarChart04,
  BarChart05,
  BarChart06,
  BarChart07,
  BarChart08,
  BarChart09,
  BarChart10,
  BarChart11,
  BarChart12,
  BarChartCircle01,
  BarChartCircle02,
  BarChartCircle03,
  BarChartSquare01,
  BarChartSquare02,
  BarChartSquare03,
  BarChartSquareDown,
  BarChartSquareMinus,
  BarChartSquarePlus,
  BarChartSquareUp,
  BarLineChart,
  BatteryCharging01,
  BatteryCharging02,
  BatteryEmpty,
  BatteryFull,
  BatteryLow,
  BatteryMid,
  Beaker01,
  Beaker02,
  Bell01,
  Bell02,
  Bell03,
  Bell04,
  BellMinus,
  BellOff01,
  BellOff02,
  BellOff03,
  BellPlus,
  BellRinging01,
  BellRinging02,
  BellRinging03,
  BellRinging04,
  BezierCurve01,
  BezierCurve02,
  BezierCurve03,
  BluetoothConnect,
  BluetoothOff,
  BluetoothOn,
  BluetoothSignal,
  Bold01,
  Bold02,
  BoldSquare,
  BookClosed,
  BookOpen01,
  BookOpen02,
  BookmarkAdd,
  BookmarkCheck,
  BookmarkMinus,
  BookmarkX,
  Bookmark,
  Box,
  BracketsCheck,
  BracketsEllipses,
  BracketsMinus,
  BracketsPlus,
  BracketsSlash,
  BracketsX,
  Brackets,
  Briefcase01,
  Briefcase02,
  Browser,
  Brush01,
  Brush02,
  Brush03,
  Building01,
  Building02,
  Building03,
  Building04,
  Building05,
  Building06,
  Building07,
  Building08,
  Bus,
  Calculator,
  CalendarCheck01,
  CalendarCheck02,
  CalendarDate,
  CalendarHeart01,
  CalendarHeart02,
  CalendarMinus01,
  CalendarMinus02,
  CalendarPlus01,
  CalendarPlus02,
  Calendar,
  Camera01,
  Camera02,
  Camera03,
  CameraLens,
  CameraOff,
  CameraPlus,
  Car01,
  Car02,
  Certificate01,
  Certificate02,
  ChartBreakoutCircle,
  ChartBreakoutSquare,
  CheckCircleBroken,
  CheckCircle,
  CheckDone01,
  CheckDone02,
  CheckHeart,
  CheckSquareBroken,
  CheckSquare,
  CheckVerified01,
  CheckVerified02,
  CheckVerified03,
  Check,
  ChevronDownDouble,
  ChevronDown,
  ChevronLeftDouble,
  ChevronLeft,
  ChevronRightDouble,
  ChevronRight,
  ChevronSelectorHorizontal,
  ChevronSelectorVertical,
  ChevronUpDouble,
  ChevronUp,
  ChromeCast,
  CircleCut,
  Circle,
  Clapperboard,
  ClipboardAttachment,
  ClipboardCheck,
  ClipboardDownload,
  ClipboardMinus,
  ClipboardPlus,
  ClipboardX,
  Clipboard,
  ClockCheck,
  ClockFastForward,
  ClockPlus,
  ClockRefresh,
  ClockRewind,
  ClockSnooze,
  ClockStopwatch,
  Clock,
  Cloud01,
  Cloud02,
  Cloud03,
  CloudBlank01,
  CloudBlank02,
  CloudLightning,
  CloudMoon,
  CloudOff,
  CloudRaining01,
  CloudRaining02,
  CloudRaining03,
  CloudRaining04,
  CloudRaining05,
  CloudRaining06,
  CloudSnowing01,
  CloudSnowing02,
  CloudSun01,
  CloudSun02,
  CloudSun03,
  Code01,
  Code02,
  CodeBrowser,
  CodeCircle01,
  CodeCircle02,
  CodeCircle03,
  CodeSnippet01,
  CodeSnippet02,
  CodeSquare01,
  CodeSquare02,
  Codepen,
  Coins01,
  Coins02,
  Coins03,
  Coins04,
  CoinsHand,
  CoinsStacked01,
  CoinsStacked02,
  CoinsStacked03,
  CoinsStacked04,
  CoinsSwap01,
  CoinsSwap02,
  Colors2,
  Colors,
  Columns01,
  Columns02,
  Columns03,
  Command,
  Compass01,
  Compass02,
  Compass03,
  Compass,
  Container,
  Contrast01,
  Contrast02,
  Contrast03,
  Copy01,
  Copy02,
  Copy03,
  Copy04,
  Copy05,
  Copy06,
  Copy07,
  CornerDownLeft,
  CornerDownRight,
  CornerLeftDown,
  CornerLeftUp,
  CornerRightDown,
  CornerRightUp,
  CornerUpLeft,
  CornerUpRight,
  CpuChip01,
  CpuChip02,
  CreditCard01,
  CreditCard02,
  CreditCardCheck,
  CreditCardDown,
  CreditCardDownload,
  CreditCardEdit,
  CreditCardLock,
  CreditCardMinus,
  CreditCardPlus,
  CreditCardRefresh,
  CreditCardSearch,
  CreditCardShield,
  CreditCardUp,
  CreditCardUpload,
  CreditCardX,
  Crop01,
  Crop02,
  Cryptocurrency01,
  Cryptocurrency02,
  Cryptocurrency03,
  Cryptocurrency04,
  Cube01,
  Cube02,
  Cube03,
  Cube04,
  CubeOutline,
  CurrencyBitcoinCircle,
  CurrencyBitcoin,
  CurrencyDollarCircle,
  CurrencyDollar,
  CurrencyEthereumCircle,
  CurrencyEthereum,
  CurrencyEuroCircle,
  CurrencyEuro,
  CurrencyPoundCircle,
  CurrencyPound,
  CurrencyRubleCircle,
  CurrencyRuble,
  CurrencyRupeeCircle,
  CurrencyRupee,
  CurrencyYenCircle,
  CurrencyYen,
  Cursor01,
  Cursor02,
  Cursor03,
  Cursor04,
  CursorBox,
  CursorClick01,
  CursorClick02,
  Data,
  Database01,
  Database02,
  Database03,
  Dataflow01,
  Dataflow02,
  Dataflow03,
  Dataflow04,
  Delete,
  Diamond01,
  Diamond02,
  Dice1,
  Dice2,
  Dice3,
  Dice4,
  Dice5,
  Dice6,
  Disc01,
  Disc02,
  DistributeSpacingHorizontal,
  DistributeSpacingVertical,
  Divide01,
  Divide02,
  Divide03,
  Divider,
  Dotpoints01,
  Dotpoints02,
  DotsGrid,
  DotsHorizontal,
  DotsVertical,
  Download01,
  Download02,
  Download03,
  Download04,
  DownloadCloud01,
  DownloadCloud02,
  Drop,
  Droplets01,
  Droplets02,
  Droplets03,
  Dropper,
  Edit01,
  Edit02,
  Edit03,
  Edit04,
  Edit05,
  EqualNot,
  Equal,
  Eraser,
  Expand01,
  Expand02,
  Expand03,
  Expand04,
  Expand05,
  Expand06,
  EyeOff,
  Eye,
  FaceContent,
  FaceFrown,
  FaceHappy,
  FaceIdSquare,
  FaceId,
  FaceNeutral,
  FaceSad,
  FaceSmile,
  FaceWink,
  FastBackward,
  FastForward,
  Feather,
  Figma,
  File01,
  File02,
  File03,
  File04,
  File05,
  File06,
  File07,
  FileAttachment01,
  FileAttachment02,
  FileAttachment03,
  FileAttachment04,
  FileAttachment05,
  FileCheck01,
  FileCheck02,
  FileCheck03,
  FileCode01,
  FileCode02,
  FileDownload01,
  FileDownload02,
  FileDownload03,
  FileHeart01,
  FileHeart02,
  FileHeart03,
  FileLock01,
  FileLock02,
  FileLock03,
  FileMinus01,
  FileMinus02,
  FileMinus03,
  FilePlus01,
  FilePlus02,
  FilePlus03,
  FileQuestion01,
  FileQuestion02,
  FileQuestion03,
  FileSearch01,
  FileSearch02,
  FileSearch03,
  FileShield01,
  FileShield02,
  FileShield03,
  FileX01,
  FileX02,
  FileX03,
  Film01,
  Film02,
  Film03,
  FilterFunnel01,
  FilterFunnel02,
  FilterLines,
  Fingerprint01,
  Fingerprint02,
  Fingerprint03,
  Fingerprint04,
  Flag01,
  Flag02,
  Flag03,
  Flag04,
  Flag05,
  Flag06,
  FlashOff,
  Flash,
  FlexAlignBottom,
  FlexAlignLeft,
  FlexAlignRight,
  FlexAlignTop,
  FlipBackward,
  FlipForward,
  FolderCheck,
  FolderClosed,
  FolderCode,
  FolderDownload,
  FolderLock,
  FolderMinus,
  FolderPlus,
  FolderQuestion,
  FolderSearch,
  FolderShield,
  FolderX,
  Folder,
  Framer,
  GamingPad01,
  GamingPad02,
  Gift01,
  Gift02,
  GitBranch01,
  GitBranch02,
  GitCommit,
  GitMerge,
  GitPullRequest,
  Glasses01,
  Glasses02,
  Globe01,
  Globe02,
  Globe03,
  Globe04,
  Globe05,
  Globe06,
  GlobeSlated01,
  GlobeSlated02,
  GoogleChrome,
  GraduationHat01,
  GraduationHat02,
  Grid01,
  Grid02,
  Grid03,
  GridDotsBlank,
  GridDotsBottom,
  GridDotsHorizontalCenter,
  GridDotsLeft,
  GridDotsOuter,
  GridDotsRight,
  GridDotsTop,
  GridDotsVerticalCenter,
  Hand,
  HardDrive,
  Hash01,
  Hash02,
  Heading01,
  Heading02,
  HeadingSquare,
  Headphones01,
  Headphones02,
  HeartCircle,
  HeartHand,
  HeartHexagon,
  HeartOctagon,
  HeartRounded,
  HeartSquare,
  Heart,
  Hearts,
  HelpCircle,
  HelpHexagon,
  HelpOctagon,
  HelpSquare,
  Hexagon01,
  Hexagon02,
  Home01,
  Home02,
  Home03,
  Home04,
  Home05,
  HomeLine,
  HomeSmile,
  HorizontalBarChart01,
  HorizontalBarChart02,
  HorizontalBarChart03,
  Hourglass01,
  Hourglass02,
  Hourglass03,
  Hurricane01,
  Hurricane02,
  Hurricane03,
  Image01,
  Image02,
  Image03,
  Image04,
  Image05,
  ImageCheck,
  ImageDown,
  ImageIndentLeft,
  ImageIndentRight,
  ImageLeft,
  ImagePlus,
  ImageRight,
  ImageUp,
  ImageUserCheck,
  ImageUserDown,
  ImageUserLeft,
  ImageUserPlus,
  ImageUserRight,
  ImageUserUp,
  ImageUserX,
  ImageUser,
  ImageX,
  Inbox01,
  Inbox02,
  Infinity,
  InfoCircle,
  InfoHexagon,
  InfoOctagon,
  InfoSquare,
  IntersectCircle,
  IntersectSquare,
  Italic01,
  Italic02,
  ItalicSquare,
  Key01,
  Key02,
  Keyboard01,
  Keyboard02,
  Laptop01,
  Laptop02,
  LayerSingle,
  LayersThree01,
  LayersThree02,
  LayersTwo01,
  LayersTwo02,
  LayoutAlt01,
  LayoutAlt02,
  LayoutAlt03,
  LayoutAlt04,
  LayoutBottom,
  LayoutGrid01,
  LayoutGrid02,
  LayoutLeft,
  LayoutRight,
  LayoutTop,
  LeftIndent01,
  LeftIndent02,
  LetterSpacing01,
  LetterSpacing02,
  LifeBuoy01,
  LifeBuoy02,
  Lightbulb01,
  Lightbulb02,
  Lightbulb03,
  Lightbulb04,
  Lightbulb05,
  Lightning01,
  Lightning02,
  LineChartDown01,
  LineChartDown02,
  LineChartDown03,
  LineChartDown04,
  LineChartDown05,
  LineChartUp01,
  LineChartUp02,
  LineChartUp03,
  LineChartUp04,
  LineChartUp05,
  LineHeight,
  Link01,
  Link02,
  Link03,
  Link04,
  Link05,
  LinkBroken01,
  LinkBroken02,
  LinkExternal01,
  LinkExternal02,
  List,
  Loading01,
  Loading02,
  Loading03,
  Lock01,
  Lock02,
  Lock03,
  Lock04,
  LockKeyholeCircle,
  LockKeyholeSquare,
  LockUnlocked01,
  LockUnlocked02,
  LockUnlocked03,
  LockUnlocked04,
  LogIn01,
  LogIn02,
  LogIn03,
  LogIn04,
  LogOut01,
  LogOut02,
  LogOut03,
  LogOut04,
  Luggage01,
  Luggage02,
  Luggage03,
  MagicWand01,
  MagicWand02,
  Mail01,
  Mail02,
  Mail03,
  Mail04,
  Mail05,
  Map01,
  Map02,
  Mark,
  MarkerPin01,
  MarkerPin02,
  MarkerPin03,
  MarkerPin04,
  MarkerPin05,
  MarkerPin06,
  Maximize01,
  Maximize02,
  MedicalCircle,
  MedicalCross,
  MedicalSquare,
  Menu01,
  Menu02,
  Menu03,
  Menu04,
  Menu05,
  MessageAlertCircle,
  MessageAlertSquare,
  MessageChatCircle,
  MessageChatSquare,
  MessageCheckCircle,
  MessageCheckSquare,
  MessageCircle01,
  MessageCircle02,
  MessageDotsCircle,
  MessageDotsSquare,
  MessageHeartCircle,
  MessageHeartSquare,
  MessageNotificationCircle,
  MessageNotificationSquare,
  MessagePlusCircle,
  MessagePlusSquare,
  MessageQuestionCircle,
  MessageQuestionSquare,
  MessageSmileCircle,
  MessageSmileSquare,
  MessageSquare01,
  MessageSquare02,
  MessageTextCircle01,
  MessageTextCircle02,
  MessageTextSquare01,
  MessageTextSquare02,
  MessageXCircle,
  MessageXSquare,
  Microphone01,
  Microphone02,
  MicrophoneOff01,
  MicrophoneOff02,
  Microscope,
  Minimize01,
  Minimize02,
  MinusCircle,
  MinusSquare,
  Minus,
  Modem01,
  Modem02,
  Monitor01,
  Monitor02,
  Monitor03,
  Monitor04,
  Monitor05,
  Moon01,
  Moon02,
  MoonEclipse,
  MoonStar,
  Mouse,
  Move,
  MusicNote01,
  MusicNote02,
  MusicNotePlus,
  NavigationPointer01,
  NavigationPointer02,
  NavigationPointerOff01,
  NavigationPointerOff02,
  NotificationBox,
  NotificationMessage,
  NotificationText,
  Octagon,
  PackageCheck,
  PackageMinus,
  PackagePlus,
  PackageSearch,
  PackageX,
  Package,
  PaintPour,
  Paint,
  Palette,
  Paperclip,
  ParagraphSpacing,
  ParagraphWrap,
  PasscodeLock,
  Passcode,
  Passport,
  PauseCircle,
  PauseSquare,
  PenTool01,
  PenTool02,
  PenToolMinus,
  PenToolPlus,
  Pencil01,
  Pencil02,
  PencilLine,
  Pentagon,
  Percent01,
  Percent02,
  Percent03,
  Perspective01,
  Perspective02,
  Phone01,
  Phone02,
  PhoneCall01,
  PhoneCall02,
  PhoneHangUp,
  PhoneIncoming01,
  PhoneIncoming02,
  PhoneOutgoing01,
  PhoneOutgoing02,
  PhonePause,
  PhonePlus,
  PhoneX,
  Phone,
  PieChart01,
  PieChart02,
  PieChart03,
  PieChart04,
  PiggyBank01,
  PiggyBank02,
  Pilcrow01,
  Pilcrow02,
  PilcrowSquare,
  Pin01,
  Pin02,
  Placeholder,
  Plane,
  PlayCircle,
  PlaySquare,
  Play,
  PlusCircle,
  PlusSquare,
  Plus,
  Podcast,
  Polaroid,
  Power01,
  Power02,
  Power03,
  PresentationChart01,
  PresentationChart02,
  PresentationChart03,
  Printer,
  PuzzlePiece01,
  PuzzlePiece02,
  QrCode01,
  QrCode02,
  ReceiptCheck,
  Receipt,
  Recording01,
  Recording02,
  Recording03,
  Reflect01,
  Reflect02,
  RefreshCcw01,
  RefreshCcw02,
  RefreshCcw03,
  RefreshCcw04,
  RefreshCcw05,
  RefreshCw01,
  RefreshCw02,
  RefreshCw03,
  RefreshCw04,
  RefreshCw05,
  Repeat01,
  Repeat02,
  Repeat03,
  Repeat04,
  ReverseLeft,
  ReverseRight,
  RightIndent01,
  RightIndent02,
  Rocket01,
  Rocket02,
  RollerBrush,
  Route,
  Rows01,
  Rows02,
  Rows03,
  Rss01,
  Rss02,
  Ruler,
  Safe,
  Sale01,
  Sale02,
  Sale03,
  Sale04,
  Save01,
  Save02,
  Save03,
  Scale01,
  Scale02,
  Scale03,
  Scales01,
  Scales02,
  Scan,
  Scissors01,
  Scissors02,
  ScissorsCut01,
  ScissorsCut02,
  SearchLg,
  SearchMd,
  SearchRefraction,
  SearchSm,
  Send01,
  Send02,
  Send03,
  Server01,
  Server02,
  Server03,
  Server04,
  Server05,
  Server06,
  Settings01,
  Settings02,
  Settings03,
  Settings04,
  Share01,
  Share02,
  Share03,
  Share04,
  Share05,
  Share06,
  Share07,
  Shield01,
  Shield02,
  Shield03,
  ShieldDollar,
  ShieldOff,
  ShieldPlus,
  ShieldTick,
  ShieldZap,
  ShoppingBag01,
  ShoppingBag02,
  ShoppingBag03,
  ShoppingCart01,
  ShoppingCart02,
  ShoppingCart03,
  Shuffle01,
  Shuffle02,
  Signal01,
  Signal02,
  Signal03,
  Simcard,
  Skew,
  SkipBack,
  SkipForward,
  SlashCircle01,
  SlashCircle02,
  SlashDivider,
  SlashOctagon,
  Sliders01,
  Sliders02,
  Sliders03,
  Sliders04,
  Snowflake01,
  Snowflake02,
  SpacingHeight01,
  SpacingHeight02,
  SpacingWidth01,
  SpacingWidth02,
  Speaker01,
  Speaker02,
  Speaker03,
  Speedometer01,
  Speedometer02,
  Speedometer03,
  Speedometer04,
  Square,
  Stand,
  Star01,
  Star02,
  Star03,
  Star04,
  Star05,
  Star06,
  Star07,
  Stars01,
  Stars02,
  Stars03,
  StickerCircle,
  StickerSquare,
  StopCircle,
  StopSquare,
  Stop,
  Strikethrough01,
  Strikethrough02,
  StrikethroughSquare,
  Subscript,
  SunSetting01,
  SunSetting02,
  SunSetting03,
  Sun,
  Sunrise,
  Sunset,
  SwitchHorizontal01,
  SwitchHorizontal02,
  SwitchVertical01,
  SwitchVertical02,
  Table,
  Tablet01,
  Tablet02,
  Tag01,
  Tag02,
  Tag03,
  Target01,
  Target02,
  Target03,
  Target04,
  Target05,
  Telescope,
  TerminalBrowser,
  TerminalCircle,
  TerminalSquare,
  Terminal,
  TextInput,
  Thermometer01,
  Thermometer02,
  Thermometer03,
  ThermometerCold,
  ThermometerWarm,
  ThumbsDown,
  ThumbsUp,
  Ticket01,
  Ticket02,
  Toggle01Left,
  Toggle01Right,
  Toggle02Left,
  Toggle02Right,
  Toggle03Left,
  Toggle03Right,
  Tool01,
  Tool02,
  Train,
  Tram,
  Transform,
  Translate01,
  Translate02,
  Trash01,
  Trash02,
  Trash03,
  Trash04,
  TrendDown01,
  TrendDown02,
  TrendUp01,
  TrendUp02,
  Triangle,
  Trophy01,
  Trophy02,
  Truck01,
  Truck02,
  Tv01,
  Tv02,
  Tv03,
  Type01,
  Type02,
  TypeSquare,
  TypeStrikethrough01,
  TypeStrikethrough02,
  Umbrella01,
  Umbrella02,
  Umbrella03,
  Underline01,
  Underline02,
  UnderlineSquare,
  Upload01,
  Upload02,
  Upload03,
  Upload04,
  UploadCloud01,
  UploadCloud02,
  UsbFlashDrive,
  User01,
  User02,
  User03,
  UserCheck01,
  UserCheck02,
  UserCircle,
  UserDown01,
  UserDown02,
  UserEdit,
  UserLeft01,
  UserLeft02,
  UserMinus01,
  UserMinus02,
  UserPlus01,
  UserPlus02,
  UserRight01,
  UserRight02,
  UserSquare,
  UserUp01,
  UserUp02,
  UserX01,
  UserX02,
  Users01,
  Users02,
  Users03,
  UsersCheck,
  UsersDown,
  UsersEdit,
  UsersLeft,
  UsersMinus,
  UsersPlus,
  UsersRight,
  UsersUp,
  UsersX,
  Variable,
  VideoRecorderOff,
  VideoRecorder,
  Virus,
  Voicemail,
  VolumeMax,
  VolumeMin,
  VolumeMinus,
  VolumePlus,
  VolumeX,
  Wallet01,
  Wallet02,
  Wallet03,
  Wallet04,
  Wallet05,
  WatchCircle,
  WatchSquare,
  Waves,
  Webcam01,
  Webcam02,
  WifiOff,
  Wifi,
  Wind01,
  Wind02,
  Wind03,
  XCircle,
  XClose,
  XSquare,
  X,
  Youtube,
  ZapCircle,
  ZapFast,
  ZapOff,
  ZapSquare,
  Zap,
  ZoomIn,
  ZoomOut,
}

export const IconNameSet = new Set(Object.keys(icons))

export interface IconProps extends Omit<React.SVGProps<SVGSVGElement>, "ref"> {
  name: IconName
  size?: number | string
}

const _Icon = (props: IconProps, ref: Ref<SVGSVGElement>) => {
  const { name, size = "1.25rem", width, height, ...rest } = props

  const IconComponent = icons[name]

  if (!IconComponent) return null
  return (
    <IconComponent
      aria-label={name}
      width={width ?? size}
      height={height ?? size}
      style={{ flexShrink: 0 }}
      {...rest}
      ref={ref}
    />
  )
}

export const Icon = forwardRef(_Icon)
